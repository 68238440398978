import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../organisms/Pagination/Pagination";
import { H6 } from "../../typography/headings/Headings";
import Flex from "../../templates/Layout/Flex";
import Loader from "../../organisms/Loader/Loader";

import styles from "./table.module.css";

const Table = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const {
    columns,
    rows,
    paginationHandler,
    totalItems,
    paginate = false,
  } = props;
  return (
    <Flex className={styles.tableWrapper}>
      <table id="table" className={styles.table}>
        <thead>
          <tr>
            {columns.map((column, index) => {
              return <th key={index}>{column}</th>;
            })}
            <th>Action</th>
          </tr>
        </thead>
        {loading && 
          <tbody>
            <tr>
              <td colSpan={columns.length + 1}>
                <Loader />
              </td>
            </tr>
          </tbody>
        }
        {!loading && <tbody>
          {rows.length === 0 ? (
            <tr>
              <td colSpan={columns.length + 1}>No data to display</td>
            </tr>
          ) : (
            rows.map((row, index) => (
              <tr key={index}>
                {Object.keys(row).map((key, index) => {
                  if (
                    key !== "createdAt" &&
                    key !== "updatedAt" &&
                    key !== "user" &&
                    key !== "_id"
                  ) {
                    return <td key={index}>{row[key]}</td>;
                  }
                  return null;
                })}
                <td>
                  <H6
                    hover
                    onClick={() => navigate(`${location.pathname}/${row._id}`)}
                  >
                    view
                  </H6>
                </td>
              </tr>
            ))
          )}
        </tbody>}
        {paginate && (
          <tfoot>
            <tr>
              <td colSpan={columns.length + 1}>
                <Pagination
                  total={totalItems}
                  paginationHandler={paginationHandler}
                  onsetLoading={setLoading}
                />
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </Flex>
  );
};

export default Table;
