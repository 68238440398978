import { orderActions } from "../reducers/order.reducer";
import { CONSTANTS } from "../constants/constants";
import {
  getOrdersController,
  updateOrderController,
  deleteOrderController,
  updateOrderStatusController,
  // updateOrderAddressController,
} from "../../controllers/orders.controller";

export const getOrders =
  (
    page = CONSTANTS.DEFAULT_PAGE_VALUE,
    limit = CONSTANTS.DEFAULT_LIMIT_VALUE
  ) =>
  async (dispatch) => {
    dispatch(orderActions.LOAD_ORDERS_PENDING());
    try {
      const response = await getOrdersController(page, limit);
      if (response.success) {
        dispatch(
          orderActions.LOAD_ORDERS_SUCCESS({
            orders: response.data,
            total: response.total,
            pending: response.pending,
            confirmed: response.confirmed,
            shipped: response.shipped,
            delivered: response.delivered,
            cancelled: response.cancelled,
            totalSales: response.totalSales,
          })
        );
      }
      return response;
    } catch (error) {
      dispatch(orderActions.LOAD_ORDERS_ERROR({ error: error }));
      return {
        error,
      };
    }
  };

export const updateOrder = (orderId, order) => async (dispatch) => {
  dispatch(orderActions.UPDATE_ORDER_PENDING());
  try {
    const response = await updateOrderController(orderId, order);
    if (response.success) {
      dispatch(orderActions.UPDATE_ORDER_SUCCESS({ order: response.data }));
    }
    return response;
  } catch (error) {
    dispatch(orderActions.UPDATE_ORDER_ERROR({ error: error }));
    return {
      error,
    };
  }
};

export const updateOrderStatus = (orderId, status) => async (dispatch) => {
  dispatch(orderActions.UPDATE_ORDER_PENDING());
  try {
    const response = await updateOrderStatusController(orderId, {status});
    if (response.success) {
      dispatch(
        orderActions.UPDATE_ORDER_SUCCESS({ order: response.data })
      );
    }
    return response;
  } catch (error) {
    dispatch(orderActions.UPDATE_ORDER_ERROR({ error: error }));
    return {
      error,
    };
  }
};

export const deleteOrder = (orderId) => async (dispatch) => {
  dispatch(orderActions.DELETE_ORDER_PENDING());
  try {
    const response = await deleteOrderController(orderId);
    if (response.success) {
      dispatch(orderActions.DELETE_ORDER_SUCCESS({ orderId }));
    }
    return response;
  } catch (error) {
    dispatch(orderActions.DELETE_ORDER_ERROR({ error: error }));
    return {
      error,
    };
  }
};
