import { createSlice } from "@reduxjs/toolkit";

const SettingsSlice = createSlice({
  name: "settings",
  initialState: {
    loading: false,
    error: null,
    categories: [],
  },
  reducers: {
    LOAD_SETTINGS_PENDING: (state) => {
      state.loading = true;
    },
    LOAD_SETTINGS_SUCCESS: (state, action) => {
      state.categories = action.payload.categories;
      state.loading = false;
    },
    LOAD_SETTINGS_ERROR: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
    },
    ADD_CATEGORY_SUCCESS: (state, action) => {
      state.categories.push(action.payload.category);
    },
    ADD_CATEGORY_ERROR: (state, action) => {
      state.error = action.payload.error;
    },
    UPDATE_CATEGORY_SUCCESS: (state, action) => {
      const index = state.categories.findIndex(
        (category) => category._id === action.payload.id
      );
      state.categories[index] = action.payload.category;
    },
    UPDATE_CATEGORY_ERROR: (state, action) => {
      state.error = action.payload.error;
    },
    DELETE_CATEGORY_SUCCESS: (state, action) => {
      state.categories = state.categories.filter(
        (category) => category._id !== action.payload.id
      );
    },
    DELETE_CATEGORY_ERROR: (state, action) => {
      state.error = action.payload.error;
    },
  },
});

export const settingsActions = SettingsSlice.actions;
export default SettingsSlice.reducer;
