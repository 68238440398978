export const handleImageChange = ({files, product, setError, setProduct, setImageFiles}) => {
  const filesArray = Array.from(files).map((file) =>
    URL.createObjectURL(file)
  );
  if (filesArray.length > 4 || filesArray.length + product.images.length > 4) {
    setError(prevState => ({ ...prevState, images: "You can upload maximum 4 images." }));
    return;
  }
  setProduct(prevState => ({ ...prevState, images: prevState.images.concat(filesArray) }));
  setImageFiles(prevState => prevState.concat(Array.from(files)));
};

export const validate = ({product, setError, initError}) => {
  const { name, description, discount, images, options } = product;
  let isValid = true;
  if(name === undefined || name === '') {
    setError(prevState => ({ ...prevState, name: "Product name is required." }));
    isValid = false;
  } else {
    if (name.length > 50) {
      setError(prevState => ({ ...prevState, name: "Product name exceeds 50 characters." }));
      isValid = false;
    } else {
      setError(prevState => ({ ...prevState, name: false }));
    }
  }
  if(discount > 99 || discount < 0) {
    setError(prevState => ({ ...prevState, discount: "Discount should be between 0-99." }));
    isValid = false;
  }
  if(description === undefined || description === "") {
    setError(prevState => ({ ...prevState, description: "Product description is required." }));
    isValid = false;
  } else {
    if (description.length > 1000) {
      setError(prevState => ({ ...prevState, description: "Product description exceeds 1000 characters." }));
      isValid = false;
    } else {
      setError(prevState => ({ ...prevState, description: false }));
    }
  }
  if(images.length === 0) {
    setError(prevState => ({ ...prevState, images: "Product images are required." }));
    isValid = false;
  } else {
    setError(prevState => ({ ...prevState, images: false }));
  }
  if(options.length === 0) {
    setError(prevState => ({ ...prevState, options: "Product options are required." }));
    isValid = false;
  } else {
    setError(prevState => ({ ...prevState, options: false }));
  }

  if(isValid) {
    setError(initError);
  }
  return isValid;
};

export const handleRemoveImage = ({index, product, imageFiles, setProduct, setImageFiles}) => {
  const newImages = [...product.images];
  const newFiles = [...imageFiles];
  newImages.splice(index, 1);
  newFiles.splice(index, 1);
  setProduct(prevState => ({ ...prevState, images: newImages }));
  setImageFiles(newFiles);
};

export const handleImageDrop = ({droppedFiles, product, setError, setProduct, setImageFiles}) => {
  const filesArray = Array.from(droppedFiles).map((file) =>
    URL.createObjectURL(file)
  );
  if (filesArray.length > 4 || filesArray.length + product.images.length > 4) {
    setError(prevState => ({ ...prevState, images: "You can upload maximum 4 images." }));
    return;
  }
  setProduct(prevState => ({ ...prevState, images: prevState.images.concat(filesArray) }));
  setImageFiles(prevState => prevState.concat(Array.from(droppedFiles)));
};

// PRODUCT OPTIONS
export const renderSizeOptions = () => {
  const options = [];
  for (let i = 10; i <= 42; i+=2) {
    options.push({ _id: i, name: i });
  }
  for (let i = 3; i <= 10; i++) {
    options.push({ _id: `${i}x${i}`, name: `${i}x${i}` });
  }
  return options;
};

export const validateOption = ({option, setError}) => {
  let isValid = true;
  if (option.length === "") {
    setError(prevState => ({ ...prevState, length: "Select a valid size." }));
    isValid = false;
  }
  if (option.price === "") {
    setError(prevState => ({ ...prevState, price: "Price cannot be empty." }));
    isValid = false;
  } else if (option.price < 0 || option.price > 9999) {
    setError(prevState => ({ ...prevState, price: "Price should be 0 - 9999" }));
    isValid = false;
  } else {
    setError(prevState => ({ ...prevState, price: false }));
  }
  if (option.quantity === "") {
    setError(prevState => ({ ...prevState, quantity: "Each size should have a quantity." }));
    isValid = false;
  } else if (option.quantity < 0 || option.quantity > 9999) {
    setError(prevState => ({ ...prevState, quantity: "Quantity should be 0 - 9999" }));
    isValid = false;
  } else if (option.quantity % 1 !== 0) {
    setError(prevState => ({ ...prevState, quantity: "Quantity should be whole number." }));
    isValid = false;
  } else{
    setError(prevState => ({ ...prevState, quantity: false }));
  }

  if(isValid){
    setError(prevState => ({ ...prevState, length: false }));
    setError(prevState => ({ ...prevState, price: false }));
    setError(prevState => ({ ...prevState, quantity: false }));
  }

  return isValid;
};