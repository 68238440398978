import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Button from "../../../components/atoms/Button";
import FormInput from "../../../components/molecules/FormInput";
import FormTextArea from "../../../components/molecules/FormTextArea";
import Form from "../../../components/organisms/Form/Form";
import ImagePickerSingle from "../../../components/organisms/ImagePicker/ImagePickerSingle";
import Col from "../../../components/templates/Layout/Col";
import Flex from "../../../components/templates/Layout/Flex";
import Row from "../../../components/templates/Layout/Row";
import Para from "../../../components/atoms/Para";
import Error from "../../../components/atoms/Error";
import { updateCategory, deleteCategory } from "../../../store/actions/settings.action";
import { useNavigate, useParams } from "react-router-dom";
import { Buffer } from "buffer";

const initCatgory = {
  title: "",
  alt: "",
  about: "",
  description: "",
  link: "",
  value: "",
  to: "/categories/",
  image: null,
};

const initError = {
  title: false,
  image: false,
  alt: false,
  about: false,
  description: false,
  link: false,
  value: false,
  to: false,
  message: false,
};

const toFile = (buffer, name) => {
  const blob = new Blob([buffer], { type: "image/jpeg" });
  const file = new File([blob], name, { type: "image/jpeg" });
  return file;
};

const toDataUri = (image) => {
  const { data } = image;
  const base64 = Buffer.from(data).toString("base64");
  const dataUri = `data:image/jpeg;base64,${base64}`;
  return dataUri;
};

const Category = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [category, setCategory] = useState(initCatgory);
  const [error, setError] = useState(initError);
  const [message, setMessage] = useState("");
  const [image, setImage] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  
  useEffect(() => {
    const category = props.categories.find((category) => category._id === id);
    if (category) {
      setCategory(category);
      const image = toDataUri(category.image);
      setCategory((prevState) => ({ ...prevState, image: image }));
      const file = toFile(category.image.data, `${category.value}.jpg`);
      setImage(file);
    }
  }, [id, props.categories]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategory({ ...category, [name]: value });
  };

  const handleImageChange = (image) => {
    const ImageUrl = URL.createObjectURL(image);
    setCategory({ ...category, image: ImageUrl });
    setImage(image);
  };

  const handleRemoveImage = () => {
    setCategory({ ...category, image: null });
  };

  const handleImageDrop = (image) => {
    const ImageUrl = URL.createObjectURL(image); 
    setCategory({ ...category, image: ImageUrl });
    setImage(image);
  };

  const handleLinkValueToChange = (e) => {
    const { value } = e.target;
    const link = value.replace(/\s+/g, "-").toLowerCase();
    const categoryValue = value.replace(/\s+/g, "-").toLowerCase();
    const to = `/categories/${value.replace(/\s+/g, "-").toLowerCase()}`;
    setCategory({ ...category, link, value: categoryValue, to, title: value });
  };

  const validate = () => {
    let isValid = true;
    if (category.title === undefined || category.title === "") {
      setError(prevState => ({ ...prevState, title: "Title is required" }));
      isValid = false;
    } else if (category.title.length > 50) {
      setError(prevState => ({ ...prevState, title: "Title exceeds 50 characters" }));
      isValid = false;
    } else {
      setError(prevState => ({ ...prevState, title: false }));
    }

    if (category.alt === undefined || category.alt === "") {
      setError(prevState => ({ ...prevState, alt: "Alt is required" }));
      isValid = false;
    } else if (category.alt.length > 50) {
      setError(prevState => ({ ...prevState, alt: "Alt exceeds 50 characters" }));
      isValid = false;
    } else {
      setError(prevState => ({ ...prevState, alt: false }));
    }

    if (category.about === undefined || category.about === "") {
      setError(prevState => ({ ...prevState, about: "About is required" }));
      isValid = false;
    } else if (category.about.length > 1000) {
      setError(prevState => ({ ...prevState, about: "About exceeds 1000 characters" }));
      isValid = false;
    } else {
      setError(prevState => ({ ...prevState, about: false }));
    }

    if (category.description === undefined || category.description === "") {
      setError(prevState => ({ ...prevState, description: "Description is required" }));
      isValid = false;
    } else if (category.description.length > 2500) {
      setError(prevState => ({ ...prevState, description: "Description exceeds 2500 characters" }));
      isValid = false;
    } else {
      setError(prevState => ({ ...prevState, description: false }));
    }

    if (category.link === undefined || category.link === "") {
      setError(prevState => ({ ...prevState, link: "Link is required" }));
      isValid = false;
    } else if (category.link.length > 50) {
      setError(prevState => ({ ...prevState, link: "Link exceeds 50 characters" }));
      isValid = false;
    } else {
      setError(prevState => ({ ...prevState, link: false }));
    }

    if (category.value === undefined || category.value === "") {
      setError(prevState => ({ ...prevState, value: "Value is required" }));
      isValid = false;
    } else if (category.value.length > 50) {
      setError(prevState => ({ ...prevState, value: "Value exceeds 50 characters" }));
      isValid = false;
    } else {
      setError(prevState => ({ ...prevState, value: false }));
    }

    if (category.to === undefined || category.to === "") {
      setError(prevState => ({ ...prevState, to: "To is required" }));
      isValid = false;
    } else if (category.to.length > 50) {
      setError(prevState => ({ ...prevState, to: "To exceeds 50 characters" }));
      isValid = false;
    } else {
      setError(prevState => ({ ...prevState, to: false }));
    }

    if (image === null) {
      setError(prevState => ({ ...prevState, image: "Image is required" }));
      isValid = false;
    } else {
      setError(prevState => ({ ...prevState, image: false }));
    }

    if(isValid) setError(initError);
    return isValid;
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!validate()) return;
    const confirm = window.confirm("Are you sure you want to add this category?");
    if (!confirm) return;
    setLoadingUpdate(true);
    setMessage("");
    const formData = new FormData();
    formData.append("title", category.title);
    formData.append("alt", category.alt);
    formData.append("about", category.about);
    formData.append("description", category.description);
    formData.append("link", category.link);
    formData.append("value", category.value);
    formData.append("to", category.to);
    formData.append("image", image);
    dispatch(updateCategory(id, formData))
      .then((res) => {
        if(res.success){
          setMessage(res.message);
        } else {
          setError(prevState => ({ ...prevState, message: res.error }));
        }
        setLoadingUpdate(false);
      })
      .catch((err) => {
        setError(prevState => ({ ...prevState, message: err }));
        setLoadingUpdate(false);
      });
    setTimeout(() => {
      setMessage("");
      setError(initError);
    }, 3000);
  };

  const handleDelete = () => {
    const confirm = window.confirm("Are you sure you want to delete this category?");
    if (!confirm) return;
    setLoadingDelete(true);
    dispatch(deleteCategory(id))
      .then((res) => {
        if(res.success){
          setMessage(res.message);
          navigate("/dashboard/categories");
        } else {
          setError(prevState => ({ ...prevState, message: res.error }));
        }
        setLoadingDelete(false);
      })
      .catch((err) => {
        setError(prevState => ({ ...prevState, message: err }));
        setLoadingDelete(false);
      });
  };
  return (
    <Flex>
      <Form onSubmit={handleUpdate}>
        <Col fullWidth>
          <Row gap="5 rem">
            <Col flex="1">
              <Row>
                <FormInput
                  type="text"
                  label="Title"
                  name="title"
                  value={category.title}
                  placeholder="Enter title"
                  message="Do not exceed 50 characters."
                  error={error.title}
                  onChange={handleLinkValueToChange}
                  width="100%"
                  alignLeft
                />
                <FormInput
                  type="text"
                  label="Alt"
                  name="alt"
                  value={category.alt}
                  placeholder="Enter alt"
                  message="Do not exceed 50 characters."
                  error={error.alt}
                  onChange={handleInputChange}
                  width="100%"
                  alignLeft
                />
              </Row>
              <Row>
                <FormTextArea
                  label="About"
                  name="about"
                  value={category.about}
                  placeholder="Enter about the category"
                  message="Do not exceed 500 characters. This will be shown on the category page."
                  error={error.about}
                  onChange={handleInputChange}
                />
                <FormTextArea
                  label="Description"
                  name="description"
                  value={category.description}
                  placeholder="Enter description"
                  message="Do not exceed 500 characters. This will be shown on the category page."
                  error={error.description}
                  onChange={handleInputChange}
                />
              </Row>
              <Row>
                <FormInput
                  type="text"
                  label="Value"
                  name="value"
                  value={category.value}
                  placeholder="Enter value"
                  message="Do not exceed 50 characters."
                  error={error.value}
                  onChange={handleInputChange}
                  width="100%"
                  alignLeft
                />
                <FormInput
                  type="text"
                  label="To"
                  name="to"
                  value={category.to}
                  placeholder="Enter to"
                  message="Do not exceed 50 characters."
                  error={error.to}
                  onChange={handleInputChange}
                  width="100%"
                  alignLeft
                />
              </Row>
            </Col>
            <Col flex="1">
              <Row>
                <ImagePickerSingle
                  onHandleImageChange={(image) => handleImageChange(image)}
                  onHandleImageRemove={handleRemoveImage}
                  onHandleImageDrop={(image) => handleImageDrop(image)}
                  image={category.image}
                  error={error.image}
                  message="Image size should be less than 1MB."
                />
              </Row>
              <Row>
                <FormInput
                  type="text"
                  label="Link"
                  name="link"
                  value={category.link}
                  placeholder="Enter link"
                  message="Do not exceed 50 characters."
                  error={error.link}
                  onChange={handleInputChange}
                  width="100%"
                  alignLeft
                />
              </Row>
            </Col>
          </Row>
          <Row align="center">
            <Button danger type="button" onClick={handleDelete}>
              {loadingDelete ? "Deleting..." : "Delete Product"}
            </Button>
            <Button primary type="submit">
              {loadingUpdate ? "Updating..." : "Update Product"}
            </Button>
            <Button primary type="button" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            {message !== undefined ? <Para>{message}</Para> : null}
            {error.message !== undefined ? (
              <Error error={error.message} />
            ) : null}
          </Row>
        </Col>
      </Form>
    </Flex>
  );
};

export default Category;
