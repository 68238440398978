import { userActions } from "../reducers/user.reducer";
import { itemsActions } from "../reducers/items.reducer";
import { orderActions } from "../reducers/order.reducer";
import { CONSTANTS } from "../constants/constants";
import CONFIGS from "../../config/config";
import {
  loginAdminController,
  getAdminController,
  getUsersController,
  updateUserController,
} from "../../controllers/user.controller";

import {getItems} from "./items.action";
import {getOrders} from "./order.action";
import { getCategories } from "./settings.action";

export const getAllUsers =
  (
    page = CONSTANTS.DEFAULT_PAGE_VALUE,
    limit = CONSTANTS.DEFAULT_LIMIT_VALUE
  ) =>
  async (dispatch) => {
    dispatch(userActions.LOAD_USERS_PENDING());
    try {
      const response = await getUsersController(page, limit);
      if (response.success) {
        dispatch(
          userActions.LOAD_USERS_SUCCESS({
            users: response.users,
            total: response.total,
          })
        );
      }
      return response;
    } catch (error) {
      dispatch(userActions.LOAD_USERS_ERROR({ error }));
      return {
        error,
      };
    }
  };

export const loginAdmin = (body) => async (dispatch) => {
  try {
    const response = await loginAdminController(body);
    if (response.success) {
      localStorage.setItem(CONFIGS.TOKEN_KEY, response.token);
      dispatch(getAllUsers());
      dispatch(getItems());
      dispatch(getOrders());
      dispatch(getCategories());
      dispatch(userActions.LOGIN_USER({ admin: response.user }));
    }
    return response;
  } catch (error) {
    return {
      error,
    };
  }
};

export const isAdminLoggedIn = () => async (dispatch) => {
  try {
    const token = localStorage.getItem(CONFIGS.TOKEN_KEY);
    if (token) {
      const response = await getAdminController();
      if (response.success) {
        dispatch(getAllUsers());
        dispatch(getItems());
        dispatch(getOrders());
        dispatch(getCategories());
        dispatch(userActions.LOGIN_USER({ admin: response.user }));
        return true;
      } else {
        localStorage.removeItem(CONFIGS.TOKEN_KEY);
      }
    } else {
      dispatch(userActions.LOGOUT_USER());
    }
  } catch (error) {
    return {
      error,
    };
  }
  return false;
};

export const updateUser = (userId, body) => async (dispatch) => {
  dispatch(userActions.UPDATE_USER_PENDING());
  try {
    const response = await updateUserController(userId, body);
    if (response.success) {
      dispatch(userActions.UPDATE_USER_SUCCESS({ user: response.user }));
    }
    return response;
  } catch (error) {
    dispatch(userActions.UPDATE_USER_ERROR({ error }));
    return {
      error,
    };
  }
};

export const logoutAdmin = () => async (dispatch) => {
  localStorage.removeItem(CONFIGS.TOKEN_KEY);
  dispatch(userActions.CLEAR());
  dispatch(itemsActions.CLEAR());
  dispatch(orderActions.CLEAR());
};
