import React, { useRef, Fragment } from "react";
import Col from "../templates/Layout/Col";

import styles from "./atoms.module.css";

const Input = (props) => {
  const inputRef = useRef(null);
  const {
    type,
    name,
    value,
    placeholder,
    onChange,
    onBlur,
    error,
    iconName,
    fullWidth,
    width,
    cursor,
    message,
    ...rest
  } = props;

  const focusInput = () => {
    inputRef.current.focus();
  };

  return (
    <Fragment>
      <div
        className={`
            ${styles.input__box}
            ${fullWidth && styles.fullWidth}
            `}
        onClick={focusInput}
        style={{ width: width, cursor: cursor }}
      >
        {iconName && <ion-icon name={iconName}></ion-icon>}
        <input
          ref={inputRef}
          className={styles.input}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          {...rest}
        />
      </div>
      <Col>
        {error ? <span className={styles.error}>{error}</span> : 
        message ? <span className={styles.message}>{message}</span>: null}
      </Col>
    </Fragment>
  );
};

Input.defaultProps = {
  type: "text",
  name: "",
  value: "",
  placeholder: "Enter text",
  onChange: () => {},
  onBlur: () => {},
  error: "",
  iconName: "",
  fullWidth: false,
  width: "100%",
};

export default Input;
