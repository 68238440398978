import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import Loader from "../components/organisms/Loader/Loader";
import NotFound from "../components/templates/404/NotFound";
import Dashboard from "../pages/Dashboard/Dashboard";

import "./App.css";

const Authentication = lazy(() =>
  import("../pages/Authentication/Authentication")
);

function App() {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  document.title = "Remyvirgin Hairs :: Official Website";
  return (
    <div className="container">
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path="/"
            element={
              !isLoggedIn ? (
                <Authentication />
              ) : (
                <Navigate replace to="/dashboard" />
              )
            }
          />
          <Route
            path="/dashboard/*"
            element={!isLoggedIn ? <Authentication /> : <Dashboard />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
