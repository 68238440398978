import React from "react";
import Input from "../atoms/Input";
import Label from "../atoms/Label";

import styles from "./molecules.module.css";

const FormInput = (props) => {
  const {
    type,
    name,
    value,
    placeholder,
    onChange,
    onBlur,
    error,
    message,
    label,
    alignLeft,
    width,
    cursor,
    required,
    ...rest
  } = props;
  const style = {
    group: {
      width: width || "auto",
    },
  }
  return (
    <div style={style.group} className={`${styles.form__group} ${alignLeft && styles.alignLeft}`}>
      <Label text={label} htmlFor={name} />
      <Input
        id={name}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        cursor={cursor}
        message={message}
        required={required}
        {...rest}
      />
    </div>
  );
};

export default FormInput;
