import React from "react";

import styles from "./Headings.module.css";

const applyMargin = (margin) => {
  switch (margin) {
    case "n":
      return styles.marginNone;
    case "s":
      return styles.marginSmall;
    case "m":
      return styles.marginMedium;
    case "l":
      return styles.marginLarge;
    case "max":
      return styles.marginMax;
    case "top":
      return styles.marginMaxTop;
    case "bottom":
      return styles.marginMaxBottom;
    default:
      return styles.marginNone;
  }
};

const alignText = (align) => {
  switch (align) {
    case "left":
      return styles.alignLeft;
    case "center":
      return styles.alignCenter;
    case "right":
      return styles.alignRight;
    default:
      return styles.alignLeft;
  }
};

const fontWeights = (weight) => {
  switch (weight) {
    case "light":
      return styles.fontWeightLight;
    case "medium":
      return styles.fontWeightMedium;
    case "bold":
      return styles.fontWeightBold;
    default:
      return null;
  }
};

const H1 = (props) => {
  return (
    <h1
      className={`
    ${styles.h1}
    ${alignText(props.align)}
    ${applyMargin(props.margin)}
    ${fontWeights(props.fontWeight)}
    ${props.color === "light" ? styles.colorLight : null}
    ${props.color === "white" ? styles.colorWhite : null}
    ${props.hover ? styles.hover : null}
    `}
      onClick={props.onClick}
    >
      {props.children}
    </h1>
  );
};

const H2 = (props) => {
  return (
    <h2
      className={`
    ${styles.h2}
    ${alignText(props.align)}
    ${applyMargin(props.margin)}
    ${fontWeights(props.fontWeight)}
    ${props.color === "light" ? styles.colorLight : null}
    ${props.color === "white" ? styles.colorWhite : null}
    ${props.hover ? styles.hover : null}
    `}
      onClick={props.onClick}
    >
      {props.children}
    </h2>
  );
};

const H3 = (props) => {
  return (
    <h3
      className={`
    ${styles.h3}
    ${alignText(props.align)}
    ${applyMargin(props.margin)}
    ${fontWeights(props.fontWeight)}
    ${props.color === "light" ? styles.colorLight : null}
    ${props.color === "white" ? styles.colorWhite : null}
    ${props.hover ? styles.hover : null}
    `}
      onClick={props.onClick}
    >
      {props.children}
    </h3>
  );
};

const H4 = (props) => {
  return (
    <h4
      className={`
    ${styles.h4}
    ${alignText(props.align)}
    ${applyMargin(props.margin)}
    ${fontWeights(props.fontWeight)}
    ${props.color === "light" ? styles.colorLight : null}
    ${props.color === "white" ? styles.colorWhite : null}
    ${props.hover ? styles.hover : null}
    `}
      onClick={props.onClick}
    >
      {props.children}
    </h4>
  );
};

const H5 = (props) => {
  return (
    <h5
      className={`
    ${styles.h5}
    ${alignText(props.align)}
    ${applyMargin(props.margin)}
    ${fontWeights(props.fontWeight)}
    ${props.color === "light" ? styles.colorLight : null}
    ${props.color === "white" ? styles.colorWhite : null}
    ${props.hover ? styles.hover : null}
    `}
      onClick={props.onClick}
    >
      {props.children}
    </h5>
  );
};

const H6 = (props) => {
  return (
    <h6
      className={`
    ${styles.h6}
    ${alignText(props.align)}
    ${applyMargin(props.margin)}
    ${fontWeights(props.fontWeight)}
    ${props.color === "light" ? styles.colorLight : null}
    ${props.color === "white" ? styles.colorWhite : null}
    ${props.hover ? styles.hover : null}
    `}
      onClick={props.onClick}
    >
      {props.children}
    </h6>
  );
};

export { H1, H2, H3, H4, H5, H6 };
