import React from "react";
import SearchBar from "../../molecules/SearchBar";
import { H3, H5 } from "../../typography/headings/Headings";

import styles from "./content.module.css";

const Content = (props) => {
  const { title, message, children } = props;
  return (
    <div className={styles.content}>
      <SearchBar />
      <H3 margin="top">{title}</H3>
      <H5 margin="s" color="light" fontWeight="light">
        {message}
      </H5>
      {children}
    </div>
  );
};

export default Content;
