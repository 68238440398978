import React from "react";
import Label from "../atoms/Label";
import TextArea from "../atoms/TextArea";
import Col from "../templates/Layout/Col";

const FormTextArea = (props) => {
  const { label, placeholder, value, error, message, name, onChange, ...rest } = props;
  return (
    <Col fullWidth>
      <Label
        text={label}
        htmlFor={name}
      />
      <TextArea
        id={name}
        name={name}
        placeholder={placeholder}
        error={error}
        message={message}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </Col>
  );
};

FormTextArea.defaultProps = {
  placeholder: "Enter text here",
};

export default FormTextArea;