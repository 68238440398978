import React from "react";

import styles from "./atoms.module.css";

const Label = (props) => {
  const { text, htmlFor, children } = props;

  return (
    <label className={styles.label} htmlFor={htmlFor}>
      {text || children}
    </label>
  );
};

Label.defaultProps = {
  text: "",
  htmlFor: "",
};

export default Label;
