import React from "react";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "../../../components/molecules/FormInput";
import Row from "../../../components/templates/Layout/Row";
import Flex from "../../../components/templates/Layout/Flex";
import Col from "../../../components/templates/Layout/Col";
import Form from "../../../components/organisms/Form/Form";
import Button from "../../../components/atoms/Button";
import FormSelect from "../../../components/molecules/FormSelect";
import ImagePicker from "../../../components/organisms/ImagePicker/ImagePicker";
import FormTextArea from "../../../components/molecules/FormTextArea";
import { H6 } from "../../../components/typography/headings/Headings";
import Para from "../../../components/atoms/Para";
import Error from "../../../components/atoms/Error";
import { createItem } from "../../../store/actions/items.action";
import {
  handleImageChange,
  handleRemoveImage,
  handleImageDrop,
  validate,
  renderSizeOptions,
  validateOption,
} from "../functions/functions";
import Option from "./Option";

const initProduct = {
  name: "",
  description: "",
  category: "all",
  discount: 0,
  images: [],
  options: [],
};
const initOption = {
  length: "10",
  price: "",
  quantity: "",
};
const initError = {
  name: false,
  description: false,
  category: false,
  discount: false,
  images: false,
  length: false,
  quantity: false,
  options: false,
  message: false,
};

const AddProduct = (props) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.settings.categories);
  const [product, setProduct] = React.useState(initProduct);
  const [error, setError] = React.useState(initError);
  const [imageFiles, setImageFiles] = React.useState([]);
  const [option, setOption] = React.useState(initOption);
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const categoryOptions = categories.map((category) => {
    return {
      _id: category.value,
      name: category.title,
    };
  });

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    if (type === "product") {
      setProduct((prevState) => ({ ...prevState, [name]: value }));
    } else if (type === "option") {
      setOption((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const resetForm = () => {
    setProduct(initProduct);
    setOption(initOption);
    setImageFiles([]);
    setError(initError);
  };

  const addOptions = () => {
    if (!validateOption({ option, setError })) return;
    // check if size exists
    const options = [...product.options];
    const sizeExists = product.options.find(
      (opt) => opt.length === option.length
    );
    if (sizeExists) {
      const updateOption = window.confirm(
        "Size already exists. Do you want to update it?"
      );
      if (updateOption) {
        const index = options.findIndex((opt) => opt.length === option.length);
        options[index] = option;
        setProduct((prevState) => ({ ...prevState, options }));
        setOption(initOption);
      }
      return;
    }
    options.push(option);
    options.sort((a, b) => a.length - b.length);
    setProduct((prevState) => ({ ...prevState, options }));
    setOption(initOption);
  };

  const removeOption = (index) => {
    const options = [...product.options];
    options.splice(index, 1);
    setProduct((prevState) => ({ ...prevState, options }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate({ product, setError, initError })) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("name", product.name);
    formData.append("description", product.description);
    formData.append("category", product.category);
    formData.append("discount", product.discount);
    formData.append("options", JSON.stringify(product.options));
    imageFiles.forEach((file) => {
      formData.append("images", file);
    });
    dispatch(createItem(formData))
      .then((res) => {
        if (res.success) {
          setMessage(res.message);
        } else {
          setError((prevState) => ({ ...prevState, message: res.message }));
        }
        setLoading(false);
      })
      .catch((err) => {
        setError((prevState) => ({ ...prevState, message: err.message }));
        setLoading(false);
      });
    setTimeout(() => {
      setMessage("");
      setError(initError);
    }, 3000);
  };

  return (
    <Flex>
      <Form onSubmit={handleSubmit}>
        <Col fullWidth>
          <Row gap="5rem">
            <Col flex="1">
              <Row>
                <FormInput
                  type="text"
                  label="Product Name"
                  name="name"
                  value={product.name}
                  placeholder="Eg. Remyvirgin clipin, Maharani Bulk Hair, etc."
                  message="Do not exceed 50 characters while entering product name."
                  error={error.name}
                  onChange={(e) => handleInputChange(e, "product")}
                  width="100%"
                  alignLeft
                />
              </Row>
              <Row justify="between">
                <FormSelect
                  label="Category"
                  name="category"
                  value={product.category}
                  placeholder="Select Category"
                  options={categoryOptions}
                  onChange={(e) => handleInputChange(e, "product")}
                  width="100%"
                  alignLeft
                />
                <FormInput
                  type="number"
                  label="discount"
                  name="discount"
                  value={product.discount}
                  placeholder="Eg. 0, 3, 5, 10"
                  message="Enter whole value between 0-99."
                  error={error.discount}
                  onChange={(e) => handleInputChange(e, "product")}
                  width="20rem"
                  alignLeft
                />
              </Row>
              <Row>
                <FormTextArea
                  label="Product Description"
                  name="description"
                  value={product.description}
                  message="Do not exceed 1000 characters while entering product description."
                  error={error.description}
                  placeholder="Enter product Description"
                  onChange={(e) => handleInputChange(e, "product")}
                />
              </Row>
            </Col>
            <Col flex="1">
              <Row>
                <ImagePicker
                  onHandleImageChange={(files) =>
                    handleImageChange({
                      files,
                      product,
                      setError,
                      setProduct,
                      setImageFiles,
                    })
                  }
                  onHandleRemoveImage={(index) =>
                    handleRemoveImage({
                      index,
                      product,
                      setProduct,
                      imageFiles,
                      setImageFiles,
                    })
                  }
                  onHandleImageDrop={(files) =>
                    handleImageDrop({
                      droppedFiles: files,
                      product,
                      setError,
                      setProduct,
                      setImageFiles,
                    })
                  }
                  error={error.images}
                  images={product.images}
                />
              </Row>
              <Col>
                <H6>Add Size Options</H6>
                <Row align="start">
                  <FormSelect
                    label="Size"
                    name="length"
                    value={option.length}
                    placeholder="Select Size"
                    options={renderSizeOptions()}
                    error={error.length}
                    onChange={(e) => handleInputChange(e, "option")}
                    width="100%"
                    alignLeft
                  />
                  <FormInput
                    type="number"
                    label="Price ($)"
                    name="price"
                    value={option.price}
                    placeholder="Eg. 123, 333, etc."
                    message="Enter value between 0-9999."
                    error={error.price}
                    onChange={(e) => handleInputChange(e, "option")}
                    width="100%"
                    alignLeft
                  />
                  <FormInput
                    type="number"
                    label="Quantity"
                    name="quantity"
                    value={option.quantity}
                    placeholder="Eg. 123, 333, etc."
                    message="Enter whole value between 0-9999."
                    error={error.quantity}
                    onChange={(e) => handleInputChange(e, "option")}
                    width="100%"
                    alignLeft
                  />
                  <Flex margin=".8rem 0">
                    <Button primary type="button" icon onClick={addOptions}>
                      +
                    </Button>
                  </Flex>
                </Row>
                <H6>Options</H6>
                <Row wrap="wrap">
                  {product.options.length !== 0 ? (
                    product.options.map((option, index) => (
                      <Option
                        key={index}
                        option={option}
                        onClick={() => removeOption(index)}
                      />
                    ))
                  ) : error.options ? (
                    <Error error={error.options} />
                  ) : (
                    <Para>No products added yet.</Para>
                  )}
                </Row>
              </Col>
            </Col>
          </Row>
          <Row align="center">
            <Button primary type="submit">
              {loading ? "Adding..." : "Add Product"}
            </Button>
            <Button primary type="reset" onClick={resetForm}>
              Reset
            </Button>
            {message !== undefined ? <Para>{message}</Para> : null}
            {error.message !== undefined ? (
              <Error error={error.message} />
            ) : null}
          </Row>
        </Col>
      </Form>
    </Flex>
  );
};

export default AddProduct;
