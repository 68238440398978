import React, { useState } from "react";
import FormInput from "../../molecules/FormInput";
import FormSelect from "../../molecules/FormSelect";
import Button from "../../atoms/Button";
import Row from "../../templates/Layout/Row";
import Col from "../../templates/Layout/Col";

import styles from "./filter.module.css";

const Filter = (props) => {
  const { filterFunction, filters, filterOptions } = props;

  const [showFilter, setShowFilter] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);

  const toggleFilter = () => {
    setShowFilter((prevState) => !prevState);
  };
  const stateArray = filters.map((filter) => filter.name);
  const stateObject = stateArray.reduce((acc, curr) => {
    acc[curr] = "";
    return acc;
  }, {});
  const [filterState, setFilterState] = useState(stateObject);

  const applyFilter = () => {
    const filters = [];
    stateArray.forEach((state) => {
      if (filterState[state] !== "" && filterState[state] !== "all") {
        filters.push({ type: state, filter: filterState[state] });
      }
    });
    if (filters.length > 0) {
      setHasFilters(true);
    } else {
      setHasFilters(false);
    }
    filterFunction(filters);
    setShowFilter(false);
  };

  const handleStateChange = (e) => {
    const { name, value } = e.target;
    setFilterState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const clearFilter = () => {
    setFilterState(stateObject);
    filterFunction([]);
    setHasFilters(false);
    setShowFilter(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      applyFilter();
    }
  };

  return (
    <Col className={styles.filter}>
      <Row align="center" justify="between">
        {filterOptions !== undefined ? (
          <Row>
            {filterOptions.map((option) => {
              return (
                <Button
                  type={option.type}
                  key={option.name}
                  iconName={option.iconName}
                  primary={option.primary}
                  secondary={option.secondary}
                  danger={option.danger}
                  disabled={option.disabled}
                  onClick={option.onClick}
                >
                  {option.label}
                </Button>
              );
            })}
          </Row>
        ) : null}
        <Row>
          {hasFilters ? (
            <Button iconName="trash-outline" danger onClick={clearFilter}>
              Remove Filters
            </Button>
          ) : null}
          <Button iconName="filter" primary onClick={toggleFilter}>
            Filter
          </Button>
          <Button iconName="funnel" primary onClick={() => {}}>
            Sort
          </Button>
        </Row>
      </Row>
      <Row wrap="wrap">
        {showFilter ? (
          <Col>
            <Row>
              {filters.map((field) => {
                if (field.elemType === "select") {
                  return (
                    <FormSelect
                      key={field.label}
                      name={field.name}
                      label={field.label}
                      value={filterState[field.name]}
                      options={field.options}
                      onChange={handleStateChange}
                      alignLeft
                    />
                  );
                }
                return null;
              })}
            </Row>
            <Row>
              {filters.map((field) => {
                if (field.elemType === "input") {
                  return (
                    <FormInput
                      key={field.label}
                      type={field.type}
                      name={field.name}
                      label={field.label}
                      placeholder={field.placeholder}
                      value={filterState[field.name]}
                      onChange={handleStateChange}
                      onKeyPress={handleKeyPress}
                      alignLeft
                    />
                  );
                }
                return null;
              })}
            </Row>
            <Row>
              <Button iconName="checkmark" primary onClick={applyFilter}>
                Apply Filters
              </Button>
              <Button iconName="close" danger onClick={toggleFilter}>
                Close
              </Button>
            </Row>
          </Col>
        ) : null}
      </Row>
    </Col>
  );
};

Filter.defaultProps = {
  filterFunction: () => {},
  filters: [],
};

export default Filter;
