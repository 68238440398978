import React, { Fragment } from "react";

import styles from "./atoms.module.css";

const Select = (props) => {
  const { name, 
    options, 
    value, 
    onChange,
    fullWidth,
    error,
    message,
    ...rest } = props;
  return (
    <Fragment>
      <select
      className={`
        ${styles.select} 
        ${fullWidth ? styles.fullWidth : null}
      `}
      name={name}
      value={value}
      onChange={onChange}
      {...rest}
      >
        {options.map((option) => (
          <option key={option._id} value={option._id}>
            {option.name}
          </option>
        ))}
      </select>
      {error ? <span className={styles.error}>{error}</span> : 
      message ? <span className={styles.message}>{message}</span> : null}
    </Fragment>
  );
};

export default Select;
