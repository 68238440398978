import { settingsActions } from "../reducers/settings.reducer";
import {
  getCategoriesController,
  deleteCategoryController,
} from "../../controllers/settings.controller";
import SETTINGS_ENDPOINTS from "../../routes/settings.route";
import CONFIGS from "../../config/config";

export const addCategory = (body) => async (dispatch) => {
  try {
    let response = await fetch(
      `${CONFIGS.BASE_URL}${SETTINGS_ENDPOINTS.ADD_CATEGORY}`,
      {
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem(CONFIGS.TOKEN_KEY)}`,
        },
        body,
      }
    );
    response = await response.json();
    if (response.success) {
      dispatch(
        settingsActions.ADD_CATEGORY_SUCCESS({ category: response.data })
      );
    } else {
      dispatch(settingsActions.ADD_CATEGORY_ERROR({ error: response.error }));
    }
    return response;
  } catch (error) {
    dispatch(settingsActions.ADD_CATEGORY_ERROR({ error: error.message }));
    return {
      error,
    };
  }
};

export const getCategories = () => async (dispatch) => {
  settingsActions.LOAD_SETTINGS_PENDING();
  try {
    const response = await getCategoriesController();
    if (response.success) {
      dispatch(
        settingsActions.LOAD_SETTINGS_SUCCESS({ categories: response.data })
      );
    } else {
      dispatch(settingsActions.LOAD_SETTINGS_ERROR({ error: response.error }));
    }
    return response;
  } catch (error) {
    dispatch(settingsActions.LOAD_SETTINGS_ERROR({ error: error.message }));
    return {
      error,
    };
  }
};

export const updateCategory = (id, body) => async (dispatch) => {
  try {
    let response = await fetch(
      `${CONFIGS.BASE_URL}${SETTINGS_ENDPOINTS.UPDATE_CATEGORY(id)}`,
      {
        method: "PUT",
        headers: {
          authorization: `Bearer ${localStorage.getItem(CONFIGS.TOKEN_KEY)}`,
        },
        body,
      }
    );
    response = await response.json();
    if (response.success) {
      dispatch(
        settingsActions.UPDATE_CATEGORY_SUCCESS({
          category: response.data,
          id: id,
        })
      );
    } else {
      dispatch(
        settingsActions.UPDATE_CATEGORY_ERROR({ error: response.error })
      );
    }
    return response;
  } catch (error) {
    dispatch(settingsActions.UPDATE_CATEGORY_ERROR({ error: error.message }));
    return {
      error,
    };
  }
};

export const deleteCategory = (id) => async (dispatch) => {
  try {
    const response = await deleteCategoryController(id);
    if (response.success) {
      dispatch(settingsActions.DELETE_CATEGORY_SUCCESS({ id: id }));
    } else {
      dispatch(settingsActions.DELETE_CATEGORY_ERROR({ error: response.error }));
    }
    return response;
  } catch (error) {
    dispatch(settingsActions.DELETE_CATEGORY_ERROR({ error: error.message }));
    return {
      error,
    };
  }
};