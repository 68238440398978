import React, { useRef } from "react";
import Para from "../../atoms/Para";
import Col from "../../templates/Layout/Col";
import Row from "../../templates/Layout/Row";
import { H6 } from "../../typography/headings/Headings";

import styles from "./imagePicker.module.css";

const Placeholder = (props) => {
  const { onClick, image, removeImage, ...rest } = props;
  return (
    <Row className={styles.placeholder} {...rest}>
      {image !== null ? (
          <img title="Remove image" src={image} alt="Products" onClick={removeImage} />
      ) : (
        <p>
          Drop your images here, or select{" "}
          <span onClick={onClick}>images to upload</span>
        </p>
      )}
    </Row>
  );
};

const ImagePicker = (props) => {
  const { onHandleImageChange, onHandleImageRemove, onHandleImageDrop, image, error } = props;
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };
  
  const handleInputAdd = (e) => {
    onHandleImageChange(e.target.files[0]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    onHandleImageDrop(file);
  };

  return (
    <Col>
      <H6>Product Images</H6>
      <Row>
        <Placeholder
          image={image}
          onClick={handleClick}
          removeImage={onHandleImageRemove}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        />
      </Row>
      <Row>
        <Para fontSize=".75rem">
          You need to add at just 1 image. Pay attention to the quality of the pictures you add, comply with
          the background color standards. Pictures must be in certain dimensions. Notice that the product
          shows all the details
        </Para>
        <input ref={inputRef} type="file" hidden multiple onChange={handleInputAdd} />
      </Row>
        {error !== undefined ? <span className={styles.error}>{error}</span> : null}
    </Col>
  );
};

export default ImagePicker;
