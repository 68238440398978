const USER_BASE_URL = "/api/v1/admin";

const USER_ENDPOINTS = {
  // ADMIN ROUTES
  LOGIN_ADMIN: `${USER_BASE_URL}/login`,
  GET_ADMIN: `${USER_BASE_URL}/`,
  GET_USERS: (page, limit) => `${USER_BASE_URL}/getAllUsers?page=${page}&limit=${limit}`,  // gives back the users and the total count of users,
  UPDATE_USER: (userId) => USER_BASE_URL + `/updateUser/${userId}`, // gives back the updated user
  DELETE_USER: (userId) => USER_BASE_URL + `/deleteUser/${userId}`, // gives back the deleted user
};

export default USER_ENDPOINTS;
