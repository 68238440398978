export const toDateString = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString([], options);
};

export const toDDMMYYYY = (date) => {
  // YYYY-MM-dd
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const dateArr = new Date(date).toLocaleDateString([], options).split('/');
  const dateString = `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`;
  return dateString;
};