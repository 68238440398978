import React, { Fragment } from "react";

import styles from "./atoms.module.css";

const TextArea = (props) => {
  const { placeholder, value, error, message, name, onChange, ...rest } = props;
  return (
      <Fragment>
        <textarea
          name={name}
          className={styles.textarea}
          placeholder={placeholder}
          value={props.value}
          onChange={onChange}
          {...rest}
        />
        {error ? <span className={styles.error}>{error}</span> : 
        message ? <span className={styles.message}>{message}</span> : null}
      </Fragment>
  );
}

TextArea.defaultProps = {
  placeholder: "Enter text here",
};

export default TextArea;