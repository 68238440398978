const ITEM_BASE_URL = "/api/v1/item";

const ITEM_ENDPOINTS = {
  // ADMIN ROUTES
  CREATE_ITEM: `${ITEM_BASE_URL}/createItem`,
  GET_ITEMS: `${ITEM_BASE_URL}/getItems`,
  GET_ITEMS_BY_PAGINATION: (page, limit) =>
    `${ITEM_BASE_URL}/getItemsByPagination/${page}/${limit}`,
  GET_ITEM: (itemId) => `${ITEM_BASE_URL}/getItem/${itemId}`,
  UPDATE_ITEM: (itemId) => ITEM_BASE_URL + `/updateItem/${itemId}`, // gives back the updated item
  DELETE_ITEM: (itemId) => ITEM_BASE_URL + `/deleteItem/${itemId}`, // gives back the deleted item
};

export default ITEM_ENDPOINTS;
