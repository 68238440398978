import { createSlice } from "@reduxjs/toolkit";

const itemsSlice = createSlice({
  name: "items",
  initialState: {
    items: [],
    loading: false,
    error: null,
    total: 0,
    totalItemsSold: 0,
  },
  reducers: {
    CREATE_ITEM: (state, action) => {
      state.items = [...state.items, action.payload.item];
    },
    UPDATE_ITEM: (state, action) => {
      const index = state.items.findIndex(
        (item) => item._id === action.payload.item._id
      );
      if (index !== -1) {
        state.items[index] = action.payload.item;
      }
    },
    DELETE_ITEM: (state, action) => {
      state.items = state.items.filter(
        (item) => item._id !== action.payload.id
      );
    },
    LOAD_ITEMS_PENDING: (state) => {
      state.loading = true;
    },
    LOAD_ITEMS_SUCCESS: (state, action) => {
      state.items = action.payload.items;
      state.total = action.payload.total;
      state.totalItemsSold = action.payload.totalItemsSold;
      state.loading = false;
    },
    LOAD_ITEMS_ERROR: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
    },
    CLEAR_ITEMS_LIST: (state) => {
      state.items = [];
    },
    CLEAR: (state) => {
      state.items = [];
      state.loading = false;
      state.error = null;
      state.total = 0;
    },
  },
});

export const itemsActions = itemsSlice.actions;
export default itemsSlice.reducer;
