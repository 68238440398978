import React from "react";
import { H4 } from "../../components/typography/headings/Headings";
import styles from "./Dashboard.module.css";

const Settings = () => {
  document.title = "Remyvirgin Hairs :: Settings";
  return (
    <div className={styles.container}>
      <H4>Coming Soon!</H4>
    </div>
  );
};

export default Settings;
