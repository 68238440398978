import React from "react";
import { NavLink as Navlink } from "react-router-dom";
import styles from "./atoms.module.css";

const NavLink = (props) => {
  const { to, children, iconName, ...rest } = props;
  return (
    <Navlink
      to={to}
      style={({ isActive }) => {
        return {
          borderLeft: isActive && "3px solid var(--p6)",
          borderRadius: isActive && "3px",
        };
      }}
      className={`
            ${styles.navlink}
        `}
      {...rest}
    >
      {iconName && <ion-icon name={iconName}></ion-icon>}
      {children}
    </Navlink>
  );
};

NavLink.defaultProps = {
  to: "/",
  iconName: "",
};

export default NavLink;
