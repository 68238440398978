import { itemsActions } from "../reducers/items.reducer";
import { CONSTANTS } from "../constants/constants";
import {
  // createItemController,
  // getItemsController,
  deleteItemController,
  getItemsByPaginationController,
  // updateItemController,
  // getItemController,
} from "../../controllers/item.controller";
import ITEM_ENDPOINTS from "../../routes/item.route";
import CONFIGS from "../../config/config";

export const getItems =
  (
    page = CONSTANTS.DEFAULT_PAGE_VALUE,
    limit = CONSTANTS.DEFAULT_LIMIT_VALUE
  ) =>
  async (dispatch) => {
    dispatch(itemsActions.LOAD_ITEMS_PENDING());
    try {
      const response = await getItemsByPaginationController(page, limit);
      if (response.error) throw new Error(response.error);
      if (response.success) {
        dispatch(
          itemsActions.LOAD_ITEMS_SUCCESS({
            items: response.data,
            total: response.total,
            totalItemsSold: response.totalItemsSold,
          })
        );
      }
      return response;
    } catch (error) {
      dispatch(itemsActions.LOAD_ITEMS_ERROR({ error: error }));
      return {
        error,
      };
    }
  };

export const createItem = (body) => async (dispatch) => {
  try {
    let response = await fetch(
      `${CONFIGS.BASE_URL}${ITEM_ENDPOINTS.CREATE_ITEM}`,
      {
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem(CONFIGS.TOKEN_KEY)}`,
        },
        body,
      }
    );
    response = await response.json();
    if (response.success) {
      dispatch(itemsActions.CREATE_ITEM({ item: response.data }));
    }
    return response;
  } catch (error) {
    return {
      error,
    };
  }
};

export const deleteItem = (id) => async (dispatch) => {
  try {
    const response = await deleteItemController(id);
    if (response.error) throw new Error(response.error);
    if (response.success) {
      dispatch(itemsActions.DELETE_ITEM({ id }));
    }
    return response;
  } catch (error) {
    dispatch(itemsActions.LOAD_ITEMS_ERROR({ error: error }));
    return {
      error,
    };
  }
};

export const updateItem = (id, body) => async (dispatch) => {
  try {
    let response = await fetch(
      `${CONFIGS.BASE_URL}${ITEM_ENDPOINTS.UPDATE_ITEM(id)}`,
      {
        method: "PUT",
        headers: {
          authorization: `Bearer ${localStorage.getItem(CONFIGS.TOKEN_KEY)}`,
        },
        body,
      }
    );
    response = await response.json();
    if (response.success) {
      dispatch(itemsActions.UPDATE_ITEM({ item: response.data }));
    }
    return response;
  } catch (error) {
    return {
      error,
    };
  }
};
