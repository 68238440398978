const SETTINGS_BASE_URL = "/api/v1/setting";

const SETTINGS_ENDPOINTS = {
  // SETTINGS ROUTES
  ADD_CATEGORY: `${SETTINGS_BASE_URL}/addCategory`,
  GET_CATEGORIES: `${SETTINGS_BASE_URL}/getCategories`,
  UPDATE_CATEGORY: (id) => `${SETTINGS_BASE_URL}/updateCategory/${id}`,
  DELETE_CATEGORY: (id) => `${SETTINGS_BASE_URL}/deleteCategory/${id}`,
};

export default SETTINGS_ENDPOINTS;  