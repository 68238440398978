import api from "./app.controller";
import USER_ENDPOINTS from "../routes/user.route";

export const loginAdminController = async (body) =>
  await api.post(USER_ENDPOINTS.LOGIN_ADMIN, body);
export const getAdminController = async () =>
  await api.get(USER_ENDPOINTS.GET_ADMIN);
export const getUsersController = async (page, limit) =>
  await api.get(`${USER_ENDPOINTS.GET_USERS(page, limit)}`);
export const updateUserController = async (userId, body) =>
  await api.put(`${USER_ENDPOINTS.UPDATE_USER(userId)}`, body);
export const deleteUserController = async (userId) =>
  await api.delete(`${USER_ENDPOINTS.DELETE_USER(userId)}`);
