let BASE_URL = process.env.REACT_APP_DEV_BASE_URL;
if (process.env.NODE_ENV === "production") {
  BASE_URL = process.env.REACT_APP_BASE_URL;
}
const CONFIGS = {
  BASE_URL: BASE_URL,
  TOKEN_KEY: "token",
};

export default CONFIGS;
