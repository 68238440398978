import React from "react";
import Label from "../atoms/Label";
import Select from "../atoms/Select";

import styles from "./molecules.module.css";

const FormSelect = (props) => {
  const {
    name,
    options,
    value,
    onChange,
    error,
    label,
    message,
    htmlFor,
    alignLeft,
    width,
    ...rest
  } = props;

  const style = {
    group: {
      width: width || "auto",
    },
  };
  return (
    <div style={style.group}  className={`${styles.form__group} ${alignLeft && styles.alignLeft}`}>
      <Label text={label} htmlFor={htmlFor} />
      <Select
        name={name}
        options={options}
        value={value}
        onChange={onChange}
        error={error}
        fullWidth
        {...rest}
      />
    </div>
  );
};

export default FormSelect;