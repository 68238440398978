import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../store/actions/user.action";
import { H6 } from "../../../components/typography/headings/Headings";
import FormInput from "../../../components/molecules/FormInput";
import Button from "../../../components/atoms/Button";
import BackLink from "../../../components/molecules/BackLink";
import FormSelect from "../../../components/molecules/FormSelect";
import Label from "../../../components/atoms/Label";
import Col from "../../../components/templates/Layout/Col";
import Row from "../../../components/templates/Layout/Row";

import styles from "../styles/customer.module.css";

const Customer = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { customers } = props;
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const customer = customers.find((customer) => customer._id === id);
  useEffect(() => {
    if(!customer) return;
    setName(customer.name);
    setPhone(customer.phone);
    setEmail(customer.email);
    setRole(customer.role);
  }, [customer]);

  useEffect(() => {
    const messageTimeout = setTimeout(() => {
      setMessage("");
    }, 3000);
    return () => clearTimeout(messageTimeout);
  }, [message]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !phone || !email){
      setError('Please fill in all fields');
      return;
    }
    if(!email.includes('@')){
      setError('Please enter a valid email');
      return;
    }
    if(phone.length < 10){
      setError('Please enter a valid phone number');
      return;
    }
    const response = await dispatch(updateUser(customer._id, { name, phone, email, role }));
    if(response.error){
      setError(response.error.message || response.error);
      return;
    }
    setError('');
    setMessage(response.message+'!');
    setIsEditing(false);
  };

  const cancelEditing = () => {
    setName(customer.name);
    setPhone(customer.phone);
    setEmail(customer.email);
    setRole(customer.role);
    setIsEditing(false);
  };

  if (!customer) return <H6>Loading...</H6>;
  return (
    <Fragment>
      <BackLink />
      <div className={styles.container}>
        {isEditing ? (
          <form className={styles.form} onSubmit={handleSubmit}>
            <FormInput
              type="text"
              name="name"
              value={name}
              placeholder="Name"
              label="Name"
              htmlFor="name"
              onChange={(e) => setName(e.target.value)}
              alignLeft
            />
            <FormInput
              type="number"
              name="phone"
              value={phone}
              placeholder="Phone"
              label="Phone"
              htmlFor="phone"
              onChange={(e) => setPhone(e.target.value)}
              alignLeft
            />
            <FormInput
              type="text"
              name="email"
              value={email}
              placeholder="Email"
              label="Email"
              htmlFor="email"
              onChange={(e) => setEmail(e.target.value)}
              alignLeft
            />
            <FormSelect
              name="role"
              value={role}
              label="Role"
              htmlFor="role"
              onChange={(e) => setRole(e.target.value)}
              error={error}
              options={[
                { _id: 'admin', name: 'Admin' },
                {_id: 'manager', name: 'Manager'},
                { _id: 'user', name: 'User' },
              ]}
              alignLeft
            />
            <Row>
              <Button danger onClick={cancelEditing}>
                Cancel
              </Button>
              <Button type="submit" secondary>
                Save Changes
              </Button>
            </Row>
          </form>
        ) : (
          <Col className={styles.col}>
            <H6 color='light'>{message}</H6>
            <Label>Name: <strong>{name}</strong></Label>
            <Label>Phone: <strong>{phone}</strong></Label>
            <Label>Email: <strong>{email}</strong></Label>
            <Label>Role: <strong>{role}</strong></Label>
            <Button primary onClick={(e) => setIsEditing(true)}>
              Edit
            </Button>
          </Col>
        )}
      </div>
    </Fragment>
  );
};

export default Customer;
