import React from "react";

const Flex = (props) => {
  const {
    children,
    margin,
    padding,
    className,
    gap,
    backgroundColor,
    backgroundImage,
    backgroundPosition,
    backgroundSize,
    backgroundRepeat,
    ...rest
  } = props;
  return (
    <div
      style={{
        display: "flex",
        margin: margin ? margin : "0",
        padding: padding ? padding : "0",
        gap: gap ? gap : "0",
        backgroundColor: backgroundColor ? backgroundColor : "transparent",
        backgroundImage: backgroundImage ? backgroundImage : "none",
        backgroundPosition: backgroundPosition ? backgroundPosition : "center",
        backgroundSize: backgroundSize ? backgroundSize : "cover",
        backgroundRepeat: backgroundRepeat ? backgroundRepeat : "no-repeat",
      }}
      className={className ? className : null}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Flex;
