import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { H5, H6 } from "../../../components/typography/headings/Headings";
import FormInput from "../../../components/molecules/FormInput";
import BackLink from "../../../components/molecules/BackLink";
import Button from "../../../components/atoms/Button";
import Table from "../../../components/templates/Table/Table";
import FormSelect from "../../../components/molecules/FormSelect";
import Col from "../../../components/templates/Layout/Col";
import Row from "../../../components/templates/Layout/Row";
import Label from "../../../components/atoms/Label";
import { toDateString } from "../../../utils/formatDate";
import {
  updateOrder,
  deleteOrder,
  updateOrderStatus,
} from "../../../store/actions/order.action";

import styles from "../styles/order.module.css";

const Order = (props) => {
  const { orders, users } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [total, setTotal] = useState(0);
  const [currency, setCurrency] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");
  const order = orders.find((order) => order._id === id);

  useEffect(() => {
    const messageTimeout = setTimeout(() => {
      setMessage("");
    }, 3000);
    return () => clearTimeout(messageTimeout);
  }, [message]);

  if (!order) return <H6>Loading...</H6>;

  const findUser = (id) => {
    const user = users && users.find((user) => user._id === id);
    return user && user.name;
  };

  const columns = ["Name", "Price", "Quantity", "Total"];
  const rows = order.items.map((item) => {
    return {
      name: item.name,
      price: `${order.currency} ${item.price}`,
      quantity: item.quantity,
      total: `${order.currency} ${item.price * item.quantity}`,
    };
  });

  const deliveryStatusOptions = [
    { _id: "pending", name: "Pending" },
    { _id: "confirmed", name: "Confirmed" },
    { _id: "shipped", name: "Shipped" },
    { _id: "delivered", name: "Delivered" },
    { _id: "cancelled", name: "Cancelled" },
  ];

  const paymentStatusOptions = [
    { _id: "pending", name: "Pending" },
    { _id: "processing", name: "Processing" },
    { _id: "paid", name: "Paid" },
    { _id: "cancelled", name: "Cancelled" },
  ];

  const paymentOptions = [
    { _id: "cash", name: "Cash" },
    { _id: "card", name: "Card" },
    { _id: "paypal", name: "Paypal" },
    { _id: "stripe", name: "Stripe" },
  ];

  const currencyOptions = [
    { _id: "$", name: "$" },
    { _id: "€", name: "€" },
    { _id: "£", name: "£" },
    { _id: "₹", name: "₹" },
  ];

  const changeOption = (e, type) => {
    switch (type) {
      case "total":
        setTotal(e.target.value);
        break;
      case "status":
        setPaymentStatus(e.target.value);
        break;
      case "method":
        setPaymentMethod(e.target.value);
        break;
      case "currency":
        setCurrency(e.target.value);
        break;
      case "delivery":
        setDeliveryStatus(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleUpdateOrderStatus = async (status) => {
    const res = await dispatch(updateOrderStatus(id, status));
    setMessage(res.message);
  };

  const confirmStatus = (status) => {
    switch (status) {
      case "pending":
        return (
          <Button
            onClick={() => handleUpdateOrderStatus("confirmed")}
            secondary
          >
            Confirm Order
          </Button>
        );
      case "confirmed":
        return (
          <Button onClick={() => handleUpdateOrderStatus("shipped")} secondary>
            Ship Order
          </Button>
        );
      case "shipped":
        return (
          <Button
            onClick={() => handleUpdateOrderStatus("delivered")}
            secondary
          >
            Deliver Order
          </Button>
        );
      case "delivered":
        break;
      case "cancelled":
        break;
      default:
        break;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const updatedOrder = {
      ...order,
      total: total === 0 ? order.total : total,
      currency: currency || order.currency,
      payment: {
        ...order.payment,
        method: paymentMethod || order.payment.method,
        status: paymentStatus || order.payment.status,
      },
      status: deliveryStatus || order.status,
    };
    const result = window.confirm("Are you sure you want to save?");
    if (result) {
      const res = await dispatch(updateOrder(order._id, updatedOrder));
      if (res.success) {
        setMessage(res.message);
        setIsEditing(false);
      } else {
        setMessage(res.message);
      }
    }
  };

  const handleDelete = async () => {
    const result = window.confirm("Are you sure you want to delete?");
    if (result) {
      const res = await dispatch(deleteOrder(order._id));
      if (res.success) {
        navigate(-1);
      } else {
        setMessage(res.message);
      }
    }
  };

  return (
    <Fragment>
      <BackLink />
      <div className={styles.container}>
        {isEditing && (
          <form className={styles.form} onSubmit={handleSave}>
            <Col>
              <H6 margin="s">Info</H6>
              <Row>
                <FormInput
                  label="Customer Name"
                  name="name"
                  type="text"
                  value={findUser(order.user)}
                  onChange={() => {}}
                  alignLeft
                  readOnly
                />
                <FormInput
                  label="Total Amount"
                  name="total"
                  type="number"
                  placeholder="Total"
                  value={total === 0 ? order.total : total}
                  onChange={(e) => changeOption(e, "total")}
                  alignLeft
                />
              </Row>
              <H6 margin="s">Payment</H6>
              <Row>
                <FormSelect
                  label="Currency"
                  name="currency"
                  value={currency || order.currency}
                  onChange={(e) => changeOption(e, "currency")}
                  alignLeft
                  options={currencyOptions}
                />
                <FormSelect
                  label="Method"
                  name="paymentMethod"
                  value={paymentMethod || order.payment.method}
                  onChange={(e) => changeOption(e, "method")}
                  alignLeft
                  options={paymentOptions}
                />
                <FormSelect
                  label="Status"
                  name="paymentStatus"
                  value={paymentStatus || order.payment.status}
                  onChange={(e) => changeOption(e, "status")}
                  alignLeft
                  options={paymentStatusOptions}
                />
              </Row>
              <H6 margin="s">Delivery</H6>
              <Row>
                <FormSelect
                  label="Status"
                  name="deliveryStatus"
                  value={deliveryStatus || order.status}
                  onChange={(e) => changeOption(e, "delivery")}
                  alignLeft
                  options={deliveryStatusOptions}
                />
              </Row>
              <H6 margin="s">Products</H6>
              <Row>
                <Table columns={columns} rows={rows} />
              </Row>
              <Row>
                <Button danger onClick={() => setIsEditing(false)}>
                  Cancel
                </Button>
                <Button type="submit" secondary>
                  Save Changes
                </Button>
              </Row>
            </Col>
          </form>
        )}
        {!isEditing && (
          <Col>
            <H5 margin="s">{findUser(order.user)}</H5>
            <H6>{message}</H6>
            <Table columns={columns} rows={rows} />
            <H6 align="right">
              Total: {order.currency} { order.total && order.total.toFixed(2)}
            </H6>
            <H6 align="right">
              Shipping Cost: {order.currency} {order.shipping && order.shipping.toFixed(2)}
            </H6>
            <H6 align="right">
              Taxes: {order.currency} {order.tax && order.tax.toFixed(2)}
            </H6>
            <H6 align="right">
              Grand Total: {order.currency} {order.grandTotal && order.grandTotal.toFixed(2)}
            </H6>
            <Row>
              <Col>
                <H6>Order Date</H6>
                <Row>
                  <Label>
                    <strong>{toDateString(order.createdAt)}</strong>
                  </Label>
                </Row>
              </Col>
              <Col>
                <H6>Delivery</H6>
                <Row>
                  <Label>
                    Status : <strong>{order.status}</strong>
                  </Label>
                </Row>
              </Col>
              <Col>
                <H6>Payment</H6>
                <Row>
                  <Label>
                    Method : <strong>{order.payment.method}</strong>
                  </Label>
                  <Label>
                    Status : <strong>{order.payment.status}</strong>
                  </Label>
                </Row>
              </Col>
            </Row>
            <Row>
              {confirmStatus(order.status)}
              <Button primary onClick={() => setIsEditing(true)}>
                Edit
              </Button>
              <Button danger onClick={handleDelete}>
                Delete
              </Button>
            </Row>
          </Col>
        )}
      </div>
    </Fragment>
  );
};

export default Order;
