import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import Filter from "../../components/organisms/Filter/Filter";
import Table from "../../components/templates/Table/Table";
import AddCategory from "../Category/components/AddCategory";
import Category from "../Category/components/Category";
import { Buffer } from "buffer";

import styles from "./Dashboard.module.css";

const filters = [
  {
    type: "text",
    label: "Category Title",
    name: "title",
    placeholder: "Category Title",
    elemType: "input",
  },
];

const toImageURL = (buffer) => {
  return new Buffer.from(buffer.data).toString("base64");
};

const CategoryIcon = (props) => {
  const { image, title } = props;
  return (
    <div className={styles.productIcon}>
      <img
        src={`data:image/png;base64,${toImageURL(image)}`}
        title={title.split(" ")[0]}
        alt={title.split(" ")[0]}
      />
    </div>
  );
};

const Categories = () => {
  document.title = "Remyvirgin Hairs :: Categories";
  const categories = useSelector((state) => state.settings.categories);
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const columns = ["Image", "Title", "Alt", "Value", "To"];

  const formatData = useMemo(
    () =>
      categories &&
      categories.map((category) => {
        return {
          image: <CategoryIcon image={category.image} title={category.title} />,
          title: category.title,
          alt: category.alt,
          value: category.value,
          to: category.to,
          _id: category._id,
        };
      }),
    [categories]
  );

  useEffect(() => {
    setRowData(formatData);
  }, [formatData]);

  const filterOptions = useMemo(() => {
    return [
      {
        type: "button",
        label: "Category List",
        name: "categoryList",
        onClick: (id) => {
          navigate("");
        },
        primary: true,
        secondary: false,
        danger: false,
        disabled: false,
        iconName: "list",
      },
      {
        type: "button",
        label: "Add New Category",
        name: "addNewCategory",
        onClick: () => {
          navigate("new");
        },
        primary: false,
        secondary: true,
        danger: false,
        disabled: false,
        iconName: "add",
      },
    ];
  }, [navigate]);

  const filterFunction = (filters) => {
    const filteredData = formatData.filter((category) => {
      let isMatch = true;
      for (const key in filters) {
        if (filters[key].type === "title") {
          isMatch = category[filters[key].type]
            .toLowerCase()
            .includes(filters[key].filter.toLowerCase());
        }
      }
      return isMatch;
    });
    setRowData(filteredData);
  };

  return (
    <div className={styles.container}>
      <Filter
        filters={filters}
        filterFunction={filterFunction}
        filterOptions={filterOptions}
      />
      <Routes>
        <Route index element={<Table columns={columns} rows={rowData} />} />
        <Route path="new" element={<AddCategory />} />
        <Route path=":id" element={<Category categories={categories} />} />
      </Routes>
    </div>
  );
};

export default Categories;
