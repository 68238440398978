import React from "react";

import styles from "./atoms.module.css";

const Para = (props) => {
  const {
    color,
    fontSize,
    fontWeight,
    lineHeight,
    textAlign,
    textTransform,
    margin,
    padding,
    ...rest
  } = props;

  const style = {
    color,
    fontSize,
    fontWeight,
    lineHeight,
    textAlign,
    textTransform,
    margin,
    padding,
  };
  return (
    <p style={style} className={styles.para} {...rest}>
      {props.children}
    </p>
  );
}

export default Para;