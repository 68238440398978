import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/user.reducer";
import orderReducer from "./reducers/order.reducer";
import itemsReducer from "./reducers/items.reducer";
import settingsReducer from "./reducers/settings.reducer";


const store = configureStore({
  reducer: {
    user: userReducer,
    order: orderReducer,
    items: itemsReducer,
    settings: settingsReducer,
  },
  // Enable Redux DevTools Extension
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export default store;
