import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    loading: false,
    error: null,
    total: 0,
    pending: 0,
    confirmed: 0,
    shipped: 0,
    delivered: 0,
    cancelled: 0,
    totalSales: 0,
  },
  reducers: {
    LOAD_ORDERS_PENDING: (state) => {
      state.loading = true;
    },
    LOAD_ORDERS_SUCCESS: (state, action) => {
      state.orders = action.payload.orders;
      state.total = action.payload.total;
      state.pending = action.payload.pending;
      state.confirmed = action.payload.confirmed;
      state.shipped = action.payload.shipped;
      state.delivered = action.payload.delivered;
      state.cancelled = action.payload.cancelled;
      state.totalSales = action.payload.totalSales;
      state.loading = false;
    },
    LOAD_ORDERS_ERROR: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
    },
    CLEAR_ORDERS_LIST: (state) => {
      state.orders = [];
    },
    UPDATE_ORDER_PENDING: (state) => {
      state.loading = true;
    },
    UPDATE_ORDER_SUCCESS: (state, action) => {
      state.orders = state.orders.map((order) =>
        order._id === action.payload.order._id ? action.payload.order : order
      );
      state.loading = false;
    },
    UPDATE_ORDER_ERROR: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
    },
    DELETE_ORDER_PENDING: (state) => {
      state.loading = true;
    },
    DELETE_ORDER_SUCCESS: (state, action) => {
      state.orders = state.orders.filter(
        (order) => order._id !== action.payload.orderId
      );
      state.loading = false;
    },
    DELETE_ORDER_ERROR: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
    },
    CLEAR: (state) => {
      state.orders = [];
      state.loading = false;
      state.error = null;
      state.total = 0;
    },
  },
});

export const orderActions = orderSlice.actions;
export default orderSlice.reducer;
