import React, { useRef } from "react";
import Para from "../../atoms/Para";
import Col from "../../templates/Layout/Col";
import Row from "../../templates/Layout/Row";
import { H6 } from "../../typography/headings/Headings";

import styles from "./imagePicker.module.css";

const Placeholder = (props) => {
  const { onClick, image, index, removeImage, ...rest } = props;
  return (
    <Row className={styles.placeholder} {...rest}>
      {image !== undefined ? (
          <img title="Remove image" src={image} tabIndex={index} alt="Products" onClick={(e) => {removeImage(e.target.tabIndex)}} />
      ) : (
        <p>
          Drop your images here, or select{" "}
          <span onClick={onClick}>images to upload</span>
        </p>
      )}
    </Row>
  );
};

const ImagePicker = (props) => {
  const { onHandleImageChange, onHandleRemoveImage, onHandleImageDrop, images, error } = props;
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };
  
  const handleInputAdd = (e) => {
    onHandleImageChange(e.target.files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    onHandleImageDrop(files);
  };

  return (
    <Col>
      <H6>Product Images</H6>
      <Row>
        <Placeholder
          onClick={handleClick}
          removeImage={onHandleRemoveImage}
          image={images[0]}
          index={0}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        />
        <Placeholder
          onClick={handleClick}
          removeImage={onHandleRemoveImage}
          image={images[1]}
          index={1}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        />
        <Col>
          <Placeholder
            flex="1 1 50%"
            onClick={handleClick}
            removeImage={onHandleRemoveImage}
            image={images[2]}
            index={2}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          />
          <Placeholder
            flex="1 1 50%"
            onClick={handleClick}
            removeImage={onHandleRemoveImage}
            image={images[3]}
            index={3}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          />
        </Col>
      </Row>
      <Row>
        <Para fontSize=".75rem">
          You need to add at least 4 images-Pay attention to the quality of the pictures you add, comply with
          the background color standards. Pictures must be in certain dimensions. Notice that the product
          shows all the details
        </Para>
        <input ref={inputRef} type="file" hidden multiple onChange={handleInputAdd} />
      </Row>
        {error !== undefined ? <span className={styles.error}>{error}</span> : null}
    </Col>
  );
};

export default ImagePicker;
