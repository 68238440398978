import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { toDateString } from "../../utils/formatDate";
import { getOrders } from "../../store/actions/order.action";
import Filter from "../../components/organisms/Filter/Filter";
import Order from "../Order/components/Order";
import Table from "../../components/templates/Table/Table";

import styles from "./Dashboard.module.css";

const filters = [
  {
    type: "date",
    label: "Start Date",
    name: "startDate",
    placeholder: "Start Date",
    elemType: "input",
  },
  {
    type: "date",
    label: "End Date",
    name: "endDate",
    placeholder: "End Date",
    elemType: "input",
  },
  {
    type: "select",
    label: "Status",
    name: "status",
    placeholder: "Select Status",
    elemType: "select",
    options: [
      { _id: "all", name: "All" },
      { _id: "pending", name: "Pending" },
      { _id: "confirmed", name: "Confirmed" },
      { _id: "shipped", name: "Shipped" },
      { _id: "delivered", name: "Delivered" },
      { _id: "cancelled", name: "Cancelled" },
    ],
  },
  {
    type: "select",
    label: "Payment Status",
    name: "paymentStatus",
    placeholder: "Select Payment Status",
    elemType: "select",
    options: [
      { _id: "all", name: "All" },
      { _id: "pending", name: "Pending" },
      { _id: "paid", name: "Paid" },
      { _id: "failed", name: "Failed" },
    ],
  },
  {
    type: "select",
    label: "Payment Method",
    name: "paymentMethod",
    placeholder: "Select Payment Method",
    elemType: "select",
    options: [
      { _id: "all", name: "All" },
      { _id: "cod", name: "Cash On Delivery" },
      { _id: "card", name: "Card" },
      { _id: "paypal", name: "Paypal" },
      { _id: "stripe", name: "Stripe" },
    ],
  },
  {
    type: "input",
    label: "Order ID",
    name: "orderId",
    placeholder: "Order ID",
    elemType: "input",
  },
  {
    type: "input",
    label: "Customer Name",
    name: "customerName",
    placeholder: "Customer Name",
    elemType: "input",
  },
];

const Orders = () => {
  document.title = "Remyvirgin Hairs :: Orders";
  const orders = useSelector((state) => state.order.orders);
  const totalOrders = useSelector((state) => state.order.total);
  const usersSelector = useSelector((state) => state.user.users);
  const [rowData, setRowData] = useState([]);
  const [users, setUsers] = useState([]);

  const orderHeaders = [
    "Order ID",
    "Order Date",
    "Items",
    "Grand Total",
    "Payment Method",
    "Payment Status",
    "Status",
  ];

  const formatData = useMemo(() =>
    orders.map((order) => {
      return {
        _id: order._id,
        id: order._id,
        date: toDateString(order.createdAt),
        items: order.items.length,
        total: `${order.currency} ${ (order.grandTotal && order.grandTotal.toFixed(2)) || order.total.toFixed(2)}`,
        paymentMethod: order.payment.method,
        paymentStatus: order.payment.status,
        status: order.status,
        createdAt: order.createdAt,
        user: order.user,
      };
    }),
    [orders]
  );

  const findUser = (id) => {
    return users.find((user) => user._id === id);
  };

  useEffect(() => {
    setRowData(formatData);
    setUsers(usersSelector);
  }, [formatData, usersSelector]);

  const filterOrders = (filters) => {
    if (filters.length === 0) {
      setRowData(formatData);
    } else {
      const filtered = formatData.filter((order) => {
        let match = true;
        filters.forEach((filter) => {
          if (filter.type === "status") {
            if (order.status !== filter.filter) {
              match = false;
            }
          } else if (filter.type === "paymentStatus") {
            if (order.paymentStatus !== filter.filter) {
              match = false;
            }
          } else if (filter.type === "paymentMethod") {
            if (order.paymentMethod !== filter.filter) {
              match = false;
            }
          } else if(filter.type === "orderId") {
            if(!order._id.toLowerCase().includes(filter.filter.toLowerCase())) {
              match = false;
            }
          } else if(filter.type === "customerName") {
            if(!findUser(order.user).name.toLowerCase().includes(filter.filter.toLowerCase())) {
              match = false;
            }
          }
        });
        let startDate = filters.find((filter) => filter.type === "startDate")
        let endDate = filters.find((filter) => filter.type === "endDate")
        if (startDate !== undefined && endDate !== undefined) {
          const orderDate = new Date(order.createdAt);
          startDate = new Date(startDate.filter);
          endDate = new Date(endDate.filter);
          if (orderDate < startDate || orderDate > endDate) {
            match = false;
          }
      }
        return match;
      });
      setRowData(filtered);
    }
  };
  return (
    <div className={styles.container}>
      <Filter filterFunction={filterOrders} filters={filters} />
      <Routes>
        <Route
          index
          element={
            <Table
              columns={orderHeaders}
              rows={rowData}
              paginate={true}
              totalItems={totalOrders}
              paginationHandler={getOrders}
            />
          }
        />
        <Route path=":id" element={<Order orders={orders} users={users} />} />
      </Routes>
    </div>
  );
};

export default Orders;
