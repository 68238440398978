import React from "react";
import { Link as Links } from "react-router-dom";

import styles from "./atoms.module.css";

const Link = ({ to, children, iconName, ...props }) => {
  return (
    <Links to={to} className={styles.link} {...props}>
      {iconName && <ion-icon name={iconName}></ion-icon>}
      {children}
    </Links>
  );
};

Link.defaultProps = {
  to: "/",
  onClick: () => {},
};

export default Link;
