import React, { forwardRef } from "react";

const Form = forwardRef((props, ref) => {
  const { children, onSubmit, className, ...rest } = props;

  const styles = {
    form: {
      display: "flex",
      width: "100%",
    },
  };

  return (
    <form ref={ref} style={styles.form} className={`${className}`} onSubmit={onSubmit} {...rest}>
      {children}
    </form>
  );
});

Form.defaultProps = {
  className: "",
  children: null,
  onSubmit: () => {},
};

export default Form;
