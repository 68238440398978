import api from "./app.controller";
import ORDER_ENDPOINTS from "../routes/orders.route";

export const getOrdersController = async (page, limit) =>
  await api.get(`${ORDER_ENDPOINTS.GET_ORDERS(page,limit)}`);
export const updateOrderController = async (orderId, order) =>
  await api.put(`${ORDER_ENDPOINTS.UPDATE_ORDER(orderId)}`, order);
export const updateOrderAddressController = async (orderId, address) =>
  await api.put(`${ORDER_ENDPOINTS.UPDATE_ORDER_ADDRESS(orderId)}`, address);
export const updateOrderStatusController = async (orderId, status) =>
  await api.put(`${ORDER_ENDPOINTS.UPDATE_ORDER_STATUS(orderId)}`, status);
export const deleteOrderController = async (orderId) =>
  await api.delete(`${ORDER_ENDPOINTS.DELETE_ORDER(orderId)}`);
