import React from "react";
import Input from "../atoms/Input";
import styles from "./molecules.module.css";

const SearchBar = () => {
  return (
    <div className={styles.searchBar}>
      <Input
        placeholder="Search for stats, users, etc."
        iconName="search"
        width="25rem"
      />
    </div>
  );
};

export default SearchBar;
