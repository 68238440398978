import React from "react";

import styles from "./atoms.module.css";

const Button = (props) => {
  const { type, onClick, disabled, dark, primary, secondary, danger, iconName } = props;

  return (
    <button
      className={`
            ${styles.button} 
            ${
              primary &&
              (!dark
                ? styles["button_primary-light"]
                : styles["button_primary-dark"])
            } 
            ${
              secondary &&
              (!dark
                ? styles["button_secondary-light"]
                : styles["button_secondary-dark"])
            }
            ${danger && styles["button_danger"]}
            ${disabled && styles["button_disabled"]}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {iconName !== undefined ? (<ion-icon name={iconName}></ion-icon>) : null}
      {props.children ? props.children : null}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
  onClick: () => {},
  disabled: false,
  dark: false,
  primary: false,
  secondary: false,
  danger: false,
};

export default Button;
