import React from "react";

const Italic = (props) => {
  const {
    children,
    fontSize = "1rem",
    fontWeight = "normal",
    margin = "0",
    color = "var(--color-black)",
  } = props;
  return (
    <em
      style={{
        fontSize,
        fontWeight,
        color,
        margin,
      }}
    >
      {children}
    </em>
  );
};

export default Italic;
