import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Routes, Route } from "react-router-dom";
import Sidebar from "../../components/organisms/Sidebar/Sidebar";
import Content from "../../components/organisms/Content/Content";
import Customers from "./Customers";
import { logoutAdmin } from "../../store/actions/user.action";

import styles from "./Dashboard.module.css";
import Products from "./Products";
import Home from "./Home";
import Orders from "./Orders";
import Reviews from "./Reviews";
import Settings from "./Settings";
import NotFound from "../../components/templates/404/NotFound";
import Row from "../../components/templates/Layout/Row";
import Categories from "./Categories";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totalItems = useSelector((state) => state.items.total);
  const totalProductsSold = useSelector((state) => state.items.totalItemsSold);
  const totalCustomers = useSelector((state) => state.user.total);
  const totalOrders = useSelector((state) => state.order.total);
  const totalPendingOrders = useSelector((state) => state.order.pending);
  const totalConfirmedOrders = useSelector((state) => state.order.confirmed);
  const totalShippedOrders = useSelector((state) => state.order.shipped);
  const totalDeliveredOrders = useSelector((state) => state.order.delivered);
  const totalCancelledOrders = useSelector((state) => state.order.cancelled);
  const totalSales = useSelector((state) => state.order.totalSales);
  document.title = "Remyvirgin Hairs :: Dashboard";

  const handleLogout = () => {
    dispatch(logoutAdmin());
    navigate("/");
  };
  const admin = useSelector((state) => state.user.admin);
  const Logo = () => (
    <>
      Remyvirgin <br /> Hairs
    </>
  );
  const navLinks = [
    {
      to: "/dashboard",
      iconName: "home-outline",
      end: true,
      children: "Home",
    },
    {
      to: "/dashboard/products",
      iconName: "pricetags-outline",
      children: "Products",
    },
    {
      to: "/dashboard/orders",
      iconName: "cart-outline",
      children: "Orders",
    },
    {
      to: "/dashboard/customers",
      iconName: "people-outline",
      children: "Customers",
    },
    {
      to: "/dashboard/reviews",
      iconName: "chatbubble-ellipses-outline",
      children: "Reviews",
    },
    {
      to: "/dashboard/categories",
      iconName: "grid-outline",
      children: "Categories",
    },
    {
      to: "/dashboard/settings",
      iconName: "settings-outline",
      children: "Settings",
    },
  ];

  const routes = [
    {
      index: true,
      element: (
        <Home
          totalItems={totalItems}
          totalOrders={totalOrders}
          totalCustomers={totalCustomers}
          totalPendingOrders={totalPendingOrders}
          totalConfirmedOrders={totalConfirmedOrders}
          totalShippedOrders={totalShippedOrders}
          totalDeliveredOrders={totalDeliveredOrders}
          totalCancelledOrders={totalCancelledOrders}
          totalProductsSold={totalProductsSold}
          totalSales={totalSales}
        />
      ),
      title: `Hello ${admin.name},`,
      message: "Welcome back!",
    },
    {
      path: "products/*",
      element: <Products />,
      title: "Products",
      message: "Manage your products",
    },
    {
      path: "customers/*",
      element: <Customers />,
      title: "Customers",
      message: "Manage your customers",
    },
    {
      path: "orders/*",
      element: <Orders />,
      title: "Orders",
      message: "Manage your orders",
    },
    {
      path: "reviews",
      element: <Reviews />,
      title: "Reviews",
      message: "Manage your reviews",
    },
    {
      path: "categories/*",
      element: <Categories />,
      title: "Categories",
      message: "Manage your categories",
    },
    {
      path: "settings",
      element: <Settings />,
      title: "Settings",
      message: "Manage your settings",
    },
  ];

  return (
    <div className={styles.dashboard}>
      <Sidebar
        logo={<Logo />}
        navLinks={navLinks}
        logoutHandler={handleLogout}
      />
      <Row fullWidth className={styles.overflow_y}>
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              index={route.index}
              path={route.path}
              element={
                <Content title={route.title} message={route.message}>
                  {route.element}
                </Content>
              }
            />
          ))}
          <Route
            path="*"
            element={
              <Content>
                <NotFound />
              </Content>
            }
          />
        </Routes>
      </Row>
    </div>
  );
};

export default Dashboard;
