import React from "react";
import Link from "../../components/atoms/Link";
import Card from "../../components/organisms/Card/Card";
import Col from "../../components/templates/Layout/Col";
import Row from "../../components/templates/Layout/Row";
import { H4, H5, H6 } from "../../components/typography/headings/Headings";
import Italic from "../../components/typography/text/Italic";
import styles from "./Dashboard.module.css";
import backdrop from "../../assets/backdrop.jpg";

const Home = (props) => {
  const links = {
    orders: "/dashboard/orders",
    products: "/dashboard/products",
    customers: "/dashboard/customers",
  };
  const {
    totalItems = 0,
    totalOrders = 0,
    totalCustomers = 0,
    totalPendingOrders = 0,
    totalConfirmedOrders = 0,
    totalShippedOrders = 0,
    totalDeliveredOrders = 0,
    totalCancelledOrders = 0,
    totalProductsSold = 0,
    totalSales = 0,
  } = props;
  return (
    <div className={styles.container}>
      <Row>
        <Col flex="1 1 70%">
          <Row>
            <Card backgroundColor="var(--color-brown)">
              <H6 fontWeight="light">Orders</H6>
            </Card>
          </Row>
          <Row>
            <Card backgroundColor="var(--color-orange)">
              <Col>
                <Link to={links.orders}>
                  <H5 fontWeight="medium">Orders</H5>
                </Link>
                <Italic margin="-.5rem 0 0 0" fontSize=".7rem">
                  Total Orders
                </Italic>
                <H4> {totalOrders} </H4>
              </Col>
            </Card>
            <Card backgroundColor="var(--order-pending)">
              <Col>
                <Link to={links.orders}>
                  <H5 fontWeight="medium">Orders</H5>
                </Link>
                <Italic margin="-.5rem 0 0 0" fontSize=".7rem">
                  Orders Pending
                </Italic>
                <H4> {totalPendingOrders} </H4>
              </Col>
            </Card>
          </Row>
          <Row>
            <Card backgroundColor="var(--order-confirmed)">
              <Col>
                <Link to={links.orders}>
                  <H5 fontWeight="medium">Orders</H5>
                </Link>
                <Italic margin="-.5rem 0 0 0" fontSize=".7rem">
                  Orders Confirmed
                </Italic>
                <H4> {totalConfirmedOrders} </H4>
              </Col>
            </Card>
            <Card backgroundColor="var(--order-shipped)">
              <Col>
                <Link to={links.orders}>
                  <H5 fontWeight="medium">Orders</H5>
                </Link>
                <Italic margin="-.5rem 0 0 0" fontSize=".7rem">
                  Orders Shipped
                </Italic>
                <H4> {totalShippedOrders} </H4>
              </Col>
            </Card>
          </Row>
          <Row>
            <Card backgroundColor="var(--order-delivered)">
              <Col>
                <Link to={links.orders}>
                  <H5 fontWeight="medium">Orders</H5>
                </Link>
                <Italic margin="-.5rem 0 0 0" fontSize=".7rem">
                  Orders Delivered
                </Italic>
                <H4> {totalDeliveredOrders} </H4>
              </Col>
            </Card>
            <Card backgroundColor="var(--order-canceled)">
              <Col>
                <Link to={links.orders}>
                  <H5 fontWeight="medium">Orders</H5>
                </Link>
                <Italic margin="-.5rem 0 0 0" fontSize=".7rem">
                  Orders Cancelled
                </Italic>
                <H4> {totalCancelledOrders} </H4>
              </Col>
            </Card>
          </Row>
          <Row>
            <Card backgroundColor="var(--color-blue)">
              <Col>
                <Link to={links.products}>
                  <H5 fontWeight="medium">Products</H5>
                </Link>
                <Italic margin="-.5rem 0 0 0" fontSize=".7rem">
                  Total Products
                </Italic>
                <H4> {totalItems} </H4>
              </Col>
            </Card>
            <Card backgroundColor="var(--color-purple)">
              <Col>
                <Link to={links.customers}>
                  <H5 fontWeight="medium">Customers</H5>
                </Link>
                <Italic margin="-.5rem 0 0 0" fontSize=".7rem">
                  Total Customers
                </Italic>
                <H4> {totalCustomers} </H4>
              </Col>
            </Card>
          </Row>
        </Col>
        {/* Side bar */}
        <Col flex="1 0 30%">
          <Row flex="1 0 auto">
            <Card
              color="white"
              backgroundImage={`linear-gradient(to bottom right,rgba(0,0,0,0.95), rgba(0,0,0,0.85)), url(${backdrop})`}
              backgroundSize="cover"
            >
              <Col gap="2rem">
                <H4 color="white">Sales Revenue</H4>
                <Row>
                  <Col>
                    <H4 color="white">
                      {new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        maximumSignificantDigits: 6,
                      }).format(totalSales)}
                    </H4>
                    <Italic
                      margin="-.5rem 0 0 0"
                      fontSize=".7rem"
                      color="white"
                    >
                      Total Sales
                    </Italic>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <H4 color="white"> {totalProductsSold} </H4>
                    <Italic
                      margin="-.5rem 0 0 0"
                      fontSize=".7rem"
                      color="white"
                    >
                      Total Products Sold
                    </Italic>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <H4 color="white"> {totalProductsSold} </H4>
                    <Italic
                      margin="-.5rem 0 0 0"
                      fontSize=".7rem"
                      color="white"
                    >
                      Total Products Sold
                    </Italic>
                  </Col>
                </Row>
              </Col>
            </Card>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
