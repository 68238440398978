import api from "./app.controller";
import ITEM_ENDPOINTS from "../routes/item.route";

export const createItemController = async (item) =>
  await api.post(`${ITEM_ENDPOINTS.CREATE_ITEM}`, item);
export const getItemsController = async () =>
  await api.get(`${ITEM_ENDPOINTS.GET_ITEMS}`);
export const getItemController = async (itemId) =>
  await api.get(`${ITEM_ENDPOINTS.GET_ITEM(itemId)}`);
export const getItemsByPaginationController = async (page, limit) =>
  await api.get(`${ITEM_ENDPOINTS.GET_ITEMS_BY_PAGINATION(page, limit)}`);
export const updateItemController = async (itemId, item) =>
  await api.put(`${ITEM_ENDPOINTS.UPDATE_ITEM(itemId)}`, item);
export const updateItemImageController = async (itemId, image) =>
  await api.put(`${ITEM_ENDPOINTS.UPDATE_ITEM_IMAGE(itemId)}`, image);
export const deleteItemController = async (itemId) =>
  await api.delete(`${ITEM_ENDPOINTS.DELETE_ITEM(itemId)}`);
