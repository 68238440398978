import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    admin: {},
    users: [],
    isLoggedIn: false,
    loading: false,
    error: null,
    total: 0,
  },
  reducers: {
    LOAD_USERS_PENDING: (state) => {
      state.loading = true;
    },
    LOAD_USERS_SUCCESS: (state, action) => {
      state.users = action.payload.users;
      state.total = action.payload.total;
      state.loading = false;
    },
    LOAD_USERS_ERROR: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
    },
    CLEAR_USERS_LIST: (state) => {
      state.users = [];
    },
    LOGIN_USER: (state, action) => {
      state.isLoggedIn = true;
      state.admin = action.payload.admin;
    },
    LOGOUT_USER: (state) => {
      state.isLoggedIn = false;
      state.admin = {};
    },
    CLEAR: (state) => {
      state.admin = {};
      state.users = [];
      state.isLoggedIn = false;
      state.loading = false;
      state.error = null;
      state.total = 0;
    },
    UPDATE_USER_PENDING: (state) => {
      state.loading = true;
    },
    UPDATE_USER_SUCCESS: (state, action) => {
      state.loading = false;
      state.users = state.users.map((user) =>
        user._id === action.payload.user._id ? action.payload.user : user
      );
    },
    UPDATE_USER_ERROR: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    }
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
