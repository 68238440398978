import React from "react";
import styles from "./atoms.module.css";

const Tag = (props) => {
  const { tag, onClick, hide } = props;
  return (
    <span className={`${styles.tag} ${hide ? styles.hide: styles.flex}`} onClick={onClick} >{tag}</span>
  );
};

export default Tag;