const ORDER_BASE_URL = "/api/v1/order";

const ORDER_ENDPOINTS = {
  // ADMIN ROUTES
  GET_ORDERS: (page, limit) =>
    `${ORDER_BASE_URL}/getAllOrders/${page}/${limit}`,
  UPDATE_ORDER: (orderId) => ORDER_BASE_URL + `/updateOrder/${orderId}`, // gives back the updated order
  UPDATE_ORDER_STATUS: (orderId) =>
    ORDER_BASE_URL + `/updateOrderStatus/${orderId}`, // gives back the updated order
  UPDATE_ORDER_ADDRESS: (orderId) =>
    ORDER_BASE_URL + `/updateOrderAddress/${orderId}`, // gives back the updated order
  DELETE_ORDER: (orderId) => ORDER_BASE_URL + `/deleteOrder/${orderId}`, // gives back the deleted order
};

export default ORDER_ENDPOINTS;