import React from "react";
import { useLocation } from "react-router-dom";
import Link from "../atoms/Link";

const BackLink = () => {
  const location = useLocation();
  const path = location.pathname.split("/");
  const pathname = path.reduce((acc, curr) => {
    if (curr !== "" && curr !== path[path.length - 1]) {
      return acc + "/" + curr;
    }
    return acc;
  }, "");
  return (
    <Link to={`${pathname}`} iconName="arrow-undo-circle-outline">Back</Link>
  )
};

export default BackLink;