import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Buffer } from "buffer";
import { Route, Routes, useNavigate } from "react-router-dom";
import Table from "../../components/templates/Table/Table";
import Filter from "../../components/organisms/Filter/Filter";
import AddProduct from "../Product/components/AddProduct";
import Product from "../Product//components/Product";
import { getItems } from "../../store/actions/items.action";

import styles from "./Dashboard.module.css";


const toImageURL = (buffer) => {
  return new Buffer.from(buffer.data).toString("base64");
};

const ProductIcon = (props) => {
  const { image, name } = props;
  return (
    <div className={styles.productIcon}>
      <img
        src={`data:image/png;base64,${toImageURL(image)}`}
        title={name.split(" ")[0]}
        alt={name.split(" ")[0]}
      />
      {name}
    </div>
  );
};

const Products = () => {
  document.title = "Remyvirgin Hairs :: Products";
  const navigate = useNavigate();
  const categories = useSelector((state) => state.settings.categories);
  const products = useSelector((state) => state.items.items);
  const totalProducts = useSelector((state) => state.items.total);
  const [rowData, setRowData] = useState([]);

  const categoryOptions = categories.map((category) => {
    return {
      _id: category.value,
      name: category.title,
    };
  });

  const filters = useMemo(() => {
    return [
      {
        type: "text",
        label: "Product Name",
        name: "name",
        placeholder: "Product Name",
        elemType: "input",
      },
      {
        type: "select",
        label: "Category",
        name: "category",
        placeholder: "Select Category",
        elemType: "select",
        options: categoryOptions,
      },
      {
        type: "number",
        label: "Starting Price",
        name: "price",
        placeholder: "Starting Price",
        elemType: "input",
      },
      {
        type: "number",
        label: "Quantity",
        name: "quantity",
        placeholder: "Quantity",
        elemType: "input",
      },
    ];
  }, [categoryOptions]);


  const columns = ["Product Name", "Category", "Starting Price", "Quantity", "Units Sold"];
  const formatData = useMemo(() =>
    products.map((product) => {
      return {
        name: <ProductIcon image={product.images[0]} name={product.name} />,
        category: product.category,
        price: product.options[0].price,
        quantity: product.options[0].quantity,
        unitsSold: product.unitsSold,
        _id: product._id,
      };
    }), [products]);

  useEffect(() => {
    setRowData(formatData);
  }, [formatData]);

  const filterOptions = useMemo(() => {
    return [
      {
        type: "button",
        label: "Product List",
        name: "productList",
        elemType: "button",
        onClick: (id) => {
          navigate("");
        },
        primary: true,
        secondary: false,
        danger: false,
        disabled: false,
        iconName: "list",
      },
      {
        type: "button",
        label: "Add New Product",
        name: "addNewProduct",
        elemType: "button",
        onClick: () => {
          navigate("new");
        },
        primary: false,
        secondary: true,
        danger: false,
        disabled: false,
        iconName: "add",
      },
    ];
  }, [navigate]);

  const filterFunction = (filters) => {
    const filteredData = formatData.filter((product) => {
      let isMatch = true;
      for (const key in filters) {
        if (filters[key].type === "name") {
          isMatch = product[filters[key].type].props.name
            .toLowerCase()
            .includes(filters[key].filter.toLowerCase());
        } else if (filters[key].type === "category") {
          if (product[filters[key].type] !== filters[key].filter) {
            isMatch = false;
          }
        } else if (
          filters[key].type === "price" ||
          filters[key].type === "quantity"
        ) {
          if (product[filters[key].type] < filters[key].filter) {
            isMatch = false;
          }
        }
      }
      return isMatch;
    });
    setRowData(filteredData);
  };

  return (
    <Fragment>
      <div className={styles.container}>
        <Filter
          filters={filters}
          filterFunction={filterFunction}
          filterOptions={filterOptions}
        />
        <Routes>
          <Route index element={
            <Table 
              columns={columns} 
              rows={rowData}
              paginate={true}
              totalItems={totalProducts}
              paginationHandler={getItems}
            />
          } />
          <Route path="new" element={<AddProduct />} />
          <Route path=":id" element={<Product products={products} />} />
        </Routes>
      </div>
    </Fragment>
  );
};

export default Products;
