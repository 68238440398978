import React, { Fragment, useMemo, useState } from "react";
import Row from "../../templates/Layout/Row";
import FormSelect from "../../molecules/FormSelect";
import Button from "../../atoms/Button";
import { H6 } from "../../typography/headings/Headings";
import { useDispatch } from "react-redux";

const Pagination = (props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const limitOptions = useMemo(() => {
    return [
      { _id: 10, name: "10 items" },
      { _id: 15, name: "15 items"},
      { _id: 20, name: "20 items" },
      { _id: 25, name: "25 items" },
      { _id: 50, name: "50 items" },
    ];
  }, []);
  
  const {
    paginationHandler,
    onsetLoading,
    total,
  } = props;
  
  const totalPages = useMemo(() => {
    return Math.ceil(total / limit);
  }, [total, limit]);

  const handleChangeLimit = (limit) => {
    onsetLoading(true);
    dispatch(paginationHandler(1, limit))
    .then(() => {
      onsetLoading(false);
    })
    .catch(() => {
      onsetLoading(false);
    });
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (digit) => {
    if (page + digit > 0 && page + digit <= Math.ceil(total / limit)) {
      onsetLoading(true);
      setPage((prev) => prev + digit);
      dispatch(paginationHandler(page + digit, limit))
      .then(() => {
        onsetLoading(false);
      })
      .catch(() => {
        onsetLoading(false);
      });
    }
  };
  return (
    <Fragment>
      {/* Pagination and limit */}
      <Row align="center">
          <FormSelect
            label="Items per page"
            name="limit"
            options={limitOptions}
            value={limit}
            onChange={(e) => handleChangeLimit(e.target.value)}
            width="100px"
            alignLeft
          />
          <Row align="center">
            <Button
              iconName="chevron-back-outline"
              onClick={() => handleChangePage(-1)}
              primary
            ></Button>
            <H6>{`${page} of ${totalPages}`}</H6>
            <Button
              iconName="chevron-forward-outline"
              onClick={() => handleChangePage(+1)}
              primary
            ></Button>
          </Row>
        </Row>
    </Fragment>
  )
};

export default Pagination;