import React from "react";
import Tag from "../../../components/atoms/Tag";
import Flex from "../../../components/templates/Layout/Flex";
import Row from "../../../components/templates/Layout/Row";
import { H6 } from "../../../components/typography/headings/Headings";

import styles from "../styles/product.module.css";

const Option = (props) => {
  const { option, onClick } = props;
  const { length, price, quantity } = option;
  return (
    <Flex padding=".3rem .25rem" className={styles.option}>
      <Row gap=".6rem">
        <H6>{length}"</H6>
        <span className={styles.grp}>({price}$, {quantity}U)</span>
      </Row>
      <Tag tag={"x"} onClick={onClick} />
    </Flex>
  );
};

export default Option;
