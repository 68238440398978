import api from "./app.controller";
import SETTINGS_ENDPOINTS from "../routes/settings.route";

export const addCategoryController = async (body) =>
  await api.post(SETTINGS_ENDPOINTS.ADD_CATEGORY, body);
export const getCategoriesController = async () =>
  await api.get(SETTINGS_ENDPOINTS.GET_CATEGORIES);
export const updateCategoryController = async (id) =>
  await api.put(`${SETTINGS_ENDPOINTS.UPDATE_CATEGORY(id)}`);
export const deleteCategoryController = async (id) =>
  await api.delete(`${SETTINGS_ENDPOINTS.DELETE_CATEGORY(id)}`);