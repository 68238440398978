import React from "react";
import { useNavigate } from "react-router-dom";
import Link from "../../atoms/Link";
import { H1, H4 } from "../../typography/headings/Headings";

import styles from "./notfound.module.css";

const NotFound = (props) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <H1>Error 404</H1>
      <H4 color="light" fontWeight="light">
        Page not found!
      </H4>
      <Link onClick={() => navigate(-1)} iconName="arrow-undo-circle-outline">
        Go back to home
      </Link>
    </div>
  );
};

export default NotFound;
