import React from "react";
import styles from "./layout.module.css";

const Col = (props) => {
  const { children, align, justify, wrap, className, self, fullWidth, flex, gap, ...rest } = props;
  return (
    <div
      className={`
      ${styles.flex}
      ${styles.col}
      ${align ? styles[`align-${align}`] : ""}
      ${justify ? styles[`justify-${justify}`] : ""}
      ${wrap ? styles[`flex-wrap`] : ""}
      ${self ? styles[`self-${self}`] : ""}
      ${fullWidth ? styles.fullWidth : ""}
      ${className ? className : ""}
    `}
      style={{ flex: flex ? flex : "", gap: gap ? gap : ""}}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Col;
