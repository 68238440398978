import React from "react";
import Flex from "../../templates/Layout/Flex";

import styles from "./card.module.css";

const Card = (props) => {
  const {
    children,
    backgroundColor = "transparent",
    backgroundImage = "none",
    backgroundPosition = "center",
    backgroundSize = "cover",
    backgroundRepeat = "no-repeat",
    color,
  } = props;
  return (
    <Flex
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPosition={backgroundPosition}
      backgroundSize={backgroundSize}
      backgroundRepeat={backgroundRepeat}
      className={`
        ${styles.card}
        ${color ? styles[color] : ""}
      `}
      padding="1.2rem"
    >
      {children}
    </Flex>
  );
};

export default Card;
