import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { getAllUsers } from "../../store/actions/user.action";
import Filter from "../../components/organisms/Filter/Filter";
import Table from "../../components/templates/Table/Table";
import Customer from "../Customer/components/Customer";

import styles from "./Dashboard.module.css";

const filters = [
  {
    type: "search",
    label: "Name",
    name: "name",
    placeholder: "Name",
    elemType: "input",
  },
  {
    type: "search",
    label: "Phone",
    name: "phone",
    placeholder: "Phone",
    elemType: "input",
  },
  {
    type: "search",
    label: "Email",
    name: "email",
    placeholder: "Email",
    elemType: "input",
  },
  {
    type: "select",
    label: "Role",
    name: "role",
    placeholder: "Select Role",
    elemType: "select",
    options: [
      { _id: "all", name: "All" },
      { _id: "admin", name: "Admin" },
      {_id: "manager", name: "Manager"},
      { _id: "user", name: "User" },
    ],
  },
];

const Customers = () => {
  document.title = "Remyvirgin Hairs :: Customers";
  const [rowData, setRowData] = useState([]);
  const users = useSelector((state) => state.user.users);
  const totalCustomers = useSelector((state) => state.user.total);
  const customerHeaders = ["Name", "Phone", "Email", "Role"];
  const formatData = useMemo(() => 
    users.map((user) => {
      return {
        name: user.name,
        phone: user.phone,
        email: user.email,
        role: user.role,
        _id: user._id,
      };
    }), [users]);
  
  useEffect(() => {
    setRowData(formatData);
  }, [formatData]);

  const filterCustomers = (filters) => {
    const filteredData = formatData.filter((user) => {
      let isMatch = true;
      for (const key in filters) {
        if (filters[key].type !== "" && !user[filters[key].type].includes(filters[key].filter)) {
          isMatch = false;
        }
      }
      return isMatch;
    });
    setRowData(filteredData);
  };

  return (
    <div className={styles.container}>
      <Filter filterFunction={filterCustomers} filters={filters} />
      <Routes>
        <Route index element={
          <Table 
            columns={customerHeaders} 
            rows={rowData}
            paginate={true}
            totalItems={totalCustomers}
            paginationHandler={getAllUsers}
          />
        } />
        <Route path=":id" element={<Customer customers={users} />} />
      </Routes>
      
    </div>
  );
};

export default Customers;
