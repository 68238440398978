import React from "react";
import Button from "../../atoms/Button";
import NavLink from "../../atoms/Navlinks";
import { H4 } from "../../typography/headings/Headings";
import styles from "./sidebar.module.css";

const Sidebar = (props) => {
  const { logoutHandler, navLinks, logo } = props;
  return (
    <div className={styles.sidebar}>
      <H4 margin="bottom">{logo}</H4>
      <div className={styles.sidebar__nav}>
        <div className={styles.sidebar__nav__links}>
          {navLinks.map((link, index) => (
            <NavLink
              key={index}
              to={link.to}
              iconName={link.iconName}
              end={link.end}
            >
              {link.children}
            </NavLink>
          ))}
        </div>
        <Button onClick={logoutHandler} secondary>Logout</Button>
      </div>
    </div>
  );
};

export default Sidebar;
